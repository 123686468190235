import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  hero: {
    background: `#F3F3F3`,
    // borderRadius: `0 0 100px 100px`,
    boxShadow: `0px -25px 100px #000`,
    position: `relative`,
    height: "100vh",

    '& h1': {
      color: `#ececec`,
      fontFamily: `Helvetica`,
      fontSize: `50px`,
      fontWeight: `800`,
      letterSpacing: `5.5px`,
      lineHeight: `70px`,
      textTransform: `uppercase`,
      margin: `13rem 0 8rem`,

      '@media (max-width: 959px)': {
        margin: `3rem 0`,
        textAlign: `center`,
      },
    },

    '& h2': {
      margin: `0 0 2rem`,
      color: `#ff3366`,
      fontFamily: `Helvetica`,
      fontSize: `20px`,
      fontWeight: `700`,
      letterSpacing: `2px`,
      lineHeight: `25px`,

      '@media (max-width: 959px)': {
        textAlign: `center`,
      },
    },

    '& .hpgBtns': {
      '& button': {
        float: 'left',
        margin: '0 10px 0 0',
      },

      '&:after': {
        display: 'block',
        content: "''",
        clear: 'both',
      },
    },

    '& img.heroLogo': {
      // maxWidth: `100%`,
      // maxHeight: `100vh`,
      // position: `relative`,
      // zIndex: 2,
      // left: `-20px`,
      height: "100%",

      '@media (max-width: 959px)': {
        display: `none`,
      },

      '@media (min-width: 1000px)': {
        top: `-70px`,
      },

      '@media (min-width: 1401px)': {
        top: `-110px`,
      },

      '@media (min-width: 1701px)': {
        top: `-150px`,
      },

      '@media (min-width: 1801px)': {
        top: `-250px`,
      },

      '@media (min-width: 2201px)': {
        top: `-300px`,
      },
    },
  },

  benefits: {
    '& h2': {
      textAlign: `center`,
      color: `#ff3366`,
      fontSize: `55px`,
      fontWeight: `800`,
      letterSpacing: `5.5px`,
      lineHeight: `70px`,
      textTransform: `uppercase`,
    },

    '& img.phoneComp': {
      maxWidth: `100%`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    },

    '& .item': {
      color: `#fff`,
      position: `relative`,
      margin: `3rem 0`,

      '& .itemContent': {
        position: `relative`,
        // left: `100px`
      },

      '& i': {
        position: `absolute`,
        // left: 0,
        top: `17px`,
        display: `block`,
        height: `2px`,
        width: `100px`,
        content: ``,
        background: `#fff`,
      },

      '& span': {
        border: `2px solid #fff`,
        borderRadius: `40px`,
        textAlign: `center`,
        padding: `5px 10px`,
        lineHeight: 2,
        margin: `0 1rem 0 0`,
      },

      '& p': {
        lineHeight: 2,
        margin: `1rem 0`,
        fontFamily: `Poppins`,

        '@media (max-width: 959px)': {
          width: `90%`,
          padding: `0 0 0 2rem`,
        },
      },
    },
  },

  leftSideBenefits: {
    '& .itemContent': {},

    '& h3': {
      textAlign: 'left',
      color: `#ff3366`,
      position: `relative`,
      left: `100px`,
    },

    '& i': {
      left: 0,
    },

    '& span': {
      margin: `0 1rem 0 0`,
    },

    '& p': {
      '@media (min-width: 959px)': {
        position: `relative`,
        left: `100px`,
      },
    },
  },
  rightSideBenefits: {
    '& .item': {
      '& h3': {
        textAlign: 'right',
        color: `#ff3366`,
        position: `relative`,
        right: `100px`,
      },
      '& .itemContent': {},

      '& i': {
        right: 0,
      },

      '& span': {
        margin: `0 0 0 1rem`,
      },

      '& p': {
        textAlign: 'right',
        '@media (min-width: 960px)': {
          position: `relative`,
          right: `100px`,
        },
      },
    },
  },
}));

export default useStyles;
