import React from 'react';
import ProRating from '../ArtistProfile/ProRating/ProRating';
import UserRating from '../ArtistProfile/UserRating/UserRating';
import Grid from '@material-ui/core/Grid';

const InvestInfo = props => {
  const {
    proRatingData,
    userRatingData,
    investInfo,
    proRating,
    userRating,
  } = props;

  return (
    <Grid item xs={5}>
      <h5 className={'investInfo'}>INFO</h5>
      <h6 className={'investInfoText'}>{investInfo}</h6>
      <div style={{ marginLeft: '-70px', marginTop: '20px' }}>
        {/* <ProRating profileInfo={proRatingData} rating={proRating}/> */}
      </div>
      <div style={{ marginLeft: '-70px', marginTop: '20px' }}>
        {/* <UserRating profileInfo={userRatingData} rating={userRating}/> */}
      </div>
    </Grid>
  );
};

export default InvestInfo;
