const firstStepSchema = {
  projectTitle: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 50,
    },
  },
  // artistOrBand: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 50,
  //   },
  // },
  // endorsedBy: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 50,
  //   },
  // },
  genreSelect: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 50,
    },
  },
  // categorySelect: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 50,
  //   },
  // },
  //   info: {
  //     presence: { allowEmpty: false, message: 'is required' },
  //     length: {
  //       maximum: 350,
  //     },
  //   },
  //   locationSelect: {
  //     presence: { allowEmpty: false, message: 'is required' },
  //     length: {
  //       maximum: 50,
  //     },
  //   },
  //   fromDate: {
  //     presence: { allowEmpty: false, message: 'is required' },
  //     length: {
  //       maximum: 50,
  //     },
  //   },
  //   toDate: {
  //     presence: { allowEmpty: false, message: 'is required' },
  //     length: {
  //       maximum: 50,
  //     },
  //   },
};

export default firstStepSchema;
