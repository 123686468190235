const registerFormSchemaStep5 = {
    firstname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },

  lastname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
  },

  citizenship: {
    presence: { allowEmpty: false, message: 'is required' },
    
  },
};

export default registerFormSchemaStep5;
