import React, { useEffect, useState } from "react";
import { CenterWrap } from "@Styled/AppStyle";
import { LogoRed } from "@Helpers/ImageRoutes/Logo";
import NaframLogo from '../../../../Assets/Images/Nafram_2x.png';
import Step1Signup from "./Form/Step1Signup";
import Step2Signup from "./Form/Step2Signup";
import Step3Signup from "./Form/Step3Signup";
import Step4Signup from "./Form/Step4Signup";
import Step5Signup from "./Form/Step5Signup";
import Step6Signup from "./Form/Step6Signup";
import Step7Signup from "./Form/Step7Signup";
import Step8Signup from "./Form/Step8Signup";
import Step9Signup from "./Form/Step9Signup";
import Step10Signup from "./Form/Step10Signup";
import Step11Signup from "./Form/Step11Signup";
import Step12Signup from "./Form/Step12Signup";
import Step999Signup from "./Form/Step999Signup";
import Grid from "@material-ui/core/Grid";
import lscache from "lscache";

import { REST, rURL } from "@Utils";

const SignUpForm = () => {
  const [error, setError] = useState({ message: null });
  const [step, setNextStep] = useState(1);

  const [mainFormState, setMainFormState] = useState({
    step1: { username: null, email: null, age: null },
    step2: { password: null, confirmPassword: null },
    step3: { artists: null },
    step4: { isInvestor: null },
  });

  const [registerError, setRegisterError] = useState(null);

  const [secondFormState, setSecondFormState] = useState({
    step5: { firstName: null, lastName: null, citizenship: null },
    step6: {
      placeOfBirth: null,
      personalIdNumber: null,
      dateOfBirth: null,
      sex: null,
    },
    step7: {
      idType: null,
      issuingAuthority: null,
      issueDate: null,
      expiration: null,
    },
    step8: { idCardPhoto: null },
    step9: { profilePhoto: null },
  });

  const firstRequest = async () => {
    var body = {
      username: mainFormState.step1.username,
      email: mainFormState.step1.email,
      password: mainFormState.step2.password,
      confirm_password: mainFormState.step2.confirmPassword,
      birthdate: "1997-06-11",
      streamingQuality: 1,
      isInvestor: mainFormState.step4.isInvestor,
    };
    var loginBody = {
      username: mainFormState.step1.username,
      password: mainFormState.step2.password,
    };

    await REST.post(`/signup`, body)
      .then((answer) => {
        setError({ message: null });

        REST.post(`${rURL}/sign-in`, loginBody)
          .then((answer) => {
            setError({ message: null });

            var artistBody = {
              artists: mainFormState.step3.artists.map((item) => item.id),
            };

            REST.post(`${rURL}/signUp/addUserArtists`, artistBody)
              .then((answer) => {
                setError({ message: null });
              })
              .catch((err) => {
                if (err && err.response && err.response.data) {
                  const { message } = err.response.data;

                  setError({
                    message,
                  });
                }
              });

            if (answer.data.body) {
              const { accessToken, email, user_id } = answer.data.body;
              lscache.set("accessToken", accessToken);
              lscache.set("userEmail", email);
              lscache.set("userId", user_id);

              if (body.isInvestor) setNextStep(5);
              else setNextStep(999);
            }
          })
          .catch((err) => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;

              setError({
                message,
              });
            }
          });
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message === "Error: Inlvalid Username") {
            setRegisterError({
              username: "taken",
            });
          }

          if (message === "Error: User already exist with given email") {
            setRegisterError({
              email: "taken",
            });
          }

          setError({
            message,
          });
        }
      });
  };
  const secondRequest = async () => {
    var body = {
      investorFirstName: secondFormState.step5.firstName,
      investorLastName: secondFormState.step5.lastName,
      investorDataOfBirth: secondFormState.step6.dateOfBirth, //"2020-11-06",
      investorIssueDate: secondFormState.step7.issueDate, //"2020-11-06",
      investorExpiration: secondFormState.step7.expiration, //"2020-11-06",
      investorCitizenship: secondFormState.step5.citizenship,
      investorPlaceOfBirth: secondFormState.step6.placeOfBirth,
      investorSex: secondFormState.step6.sex,
      investorPersonalIdNumber: secondFormState.step6.personalIdNumber,
      investorIssuingAuthority: secondFormState.step7.issuingAuthority,
      investorIDType: secondFormState.step7.idType,
      investorIDCardPhoto: secondFormState.step8.idCardPhoto,
      investorProfilePhoto: secondFormState.step9.profilePhoto,
    };

    await REST.post(`/createInvestor`, body)
      .then((answer) => {
        setError({ message: null });

        if (answer.data.body) {
          const { accessToken } = answer.data.body;
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  useEffect(() => { }, [mainFormState.step1]);
  useEffect(() => { }, [mainFormState.step2]);
  // useEffect(() => {}, [mainFormState.step3]);
  useEffect(() => {
    if (step === 4) firstRequest();
  }, [mainFormState.step4]);

  useEffect(() => { }, [secondFormState.step5]);
  useEffect(() => { }, [secondFormState.step6]);
  useEffect(() => { }, [secondFormState.step7]);
  useEffect(() => { }, [secondFormState.step8]);
  useEffect(() => {
    if (step === 9) secondRequest();
  }, [secondFormState.step9]);

  useEffect(() => {
    if (registerError && registerError.username === "taken") {
      setNextStep(1);
    }

    if (registerError && registerError.email === "taken") {
      setNextStep(1);
    }
  }, [registerError]);

  const goBackOneStep = () => {
    if (step === 999) {
      setNextStep(4);
      return;
    }

    setNextStep(step - 1);
  };

  return (
    <div>
      <Grid container spacing={3} className={`signUpLogo pt-7-rem`}>
        <Grid item xl={12} lg={12} md={12} xs={12} className={`signUpForm`}>
          {" "}
          {/* isVisible={step < 3} */}
          <img src={NaframLogo} alt="Slingshot" />
          <h1>Sign Up</h1>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} className={`signUpForm`}>
          {step > 1 && step != 4 && step != 5 && step != 999 && step != 10 && (
            <div className={`backToPrevBtn`} onClick={goBackOneStep}>
              {" "}
              &lt; Prev Step
            </div>
          )}
          {step === 1 && (
            <Step1Signup
              registerError={registerError}
              setNextStep={setNextStep}
              mainFormState={mainFormState}
              setMainFormState={setMainFormState}
              isVisible={step === 1}
            />
          )}
          {step === 2 && (
            <Step2Signup
              setNextStep={setNextStep}
              mainFormState={mainFormState}
              setMainFormState={setMainFormState}
              isVisible={step === 2}
            />
          )}
          {step === 3 && (
            <Step3Signup
              setNextStep={setNextStep}
              mainFormState={mainFormState}
              setMainFormState={setMainFormState}
              isVisible={step === 3}
            />
          )}
          {step === 4 && (
            <Step4Signup
              setNextStep={setNextStep}
              mainFormState={mainFormState}
              setMainFormState={setMainFormState}
              isVisible={step === 4}
            />
          )}

          {step === 5 && (
            <Step5Signup
              setNextStep={setNextStep}
              secondFormState={secondFormState}
              setSecondFormState={setSecondFormState}
              isVisible={step === 5}
            />
          )}

          {step === 6 && (
            <Step6Signup
              setNextStep={setNextStep}
              secondFormState={secondFormState}
              setSecondFormState={setSecondFormState}
              isVisible={step === 6}
            />
          )}

          {step === 7 && (
            <Step7Signup
              setNextStep={setNextStep}
              secondFormState={secondFormState}
              setSecondFormState={setSecondFormState}
              isVisible={step === 7}
            />
          )}

          {step === 8 && (
            <Step8Signup
              setNextStep={setNextStep}
              secondFormState={secondFormState}
              setSecondFormState={setSecondFormState}
              isVisible={step === 8}
            />
          )}

          {step === 9 && (
            <Step9Signup
              setNextStep={setNextStep}
              secondFormState={secondFormState}
              setSecondFormState={setSecondFormState}
              isVisible={step === 9}
            />
          )}
          {/* <Step10Signup setNextStep={setNextStep} isVisible={step === 10} /> */}
          {/* <Step11Signup setNextStep={setNextStep} isVisible={step === 11} /> */}
          {step === 10 && (
            <Step12Signup
              setNextStep={setNextStep}
              secondFormState={secondFormState}
              setSecondFormState={setSecondFormState}
              isVisible={step === 10}
            />
          )}
          {step === 999 && (
            <Step999Signup setNextStep={setNextStep} isVisible={step === 999} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpForm;
