import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import MarketFilters from "./Components/Filters/MarketFilters";
import Items from "./Components/Items/Items";

import { marketItemList } from "@Utils/mocks/marketItemsList";
import { RESTjava, rURL } from "@Utils";
import { useRouter } from "@Hooks";

const Market = () => {
  const Router = useRouter();
  const [marketItems, setMarketItems] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");

  const getAllProducts = async event => {
    switch (selectedFilter) {
      case "":
        await RESTjava.get(`/api/market/product/getAllProducts?page=0&size=100`)
          .then(answer => {
            var data = answer.data.content;
            setMarketItems(data);
            setIsLoading(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;
              setError({
                message
              });
            }
          });
        break;

      case "apparel":
        await RESTjava.get(
          `/api/market/product/getProductsByCategory?category=apparel&page=0&size=100`
        )
          .then(answer => {
            var data = answer.data.content;
            setMarketItems(data);
            setIsLoading(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;
              setError({
                message
              });
            }
          });
        break;

      case "accessories":
        await RESTjava.get(
          `/api/market/product/getProductsByCategory?category=accessories&page=0&size=100`
        )
          .then(answer => {
            var data = answer.data.content;
            setMarketItems(data);
            setIsLoading(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;
              setError({
                message
              });
            }
          });
        break;

      case "gifts":
        await RESTjava.get(
          `/api/market/product/getProductsByCategory?category=gifts&page=0&size=100`
        )
          .then(answer => {
            var data = answer.data.content;
            setMarketItems(data);
            setIsLoading(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;
              setError({
                message
              });
            }
          });
        break;

      case "fashion":
        await RESTjava.get(
          `/api/market/product/getProductsByCategory?category=fashion&page=0&size=100`
        )
          .then(answer => {
            var data = answer.data.content;
            setMarketItems(data);
            setIsLoading(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;
              setError({
                message
              });
            }
          });
        break;

      case "auto":
        await RESTjava.get(
          `/api/market/product/getProductsByCategory?category=auto&page=0&size=100`
        )
          .then(answer => {
            var data = answer.data.content;
            setMarketItems(data);
            setIsLoading(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;
              setError({
                message
              });
            }
          });
        break;
    }
  };

  const handleApparel = () => {
    setSelectedFilter("apparel");
  };

  const handleAccessories = () => {
    setSelectedFilter("accessories");
    console.log("muie >>><<<<>>>>");
  };

  const handleGifts = () => {
    setSelectedFilter("gifts");
    console.log("gifts >>><<<<>>>>");
  };

  const handleFashion = () => {
    setSelectedFilter("fashion");
  };

  const handleAuto = () => {
    setSelectedFilter("auto");
  };

  useEffect(() => {
    getAllProducts();
  }, [selectedFilter]);

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={"market-container"}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={"finter-container"}
        >
          <MarketFilters
            handleApparel={handleApparel}
            handleAccessories={handleAccessories}
            handleGifts={handleGifts}
            handleFashion={handleFashion}
            handleAuto={handleAuto}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          classname={"items-container"}
        >
          <Items marketItems={marketItems} />
        </Grid>
      </Grid>
    );
  }
};

export default Market;
