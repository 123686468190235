import lscache from "lscache";

const accessToken = lscache.get("accessToken");

const AuthHeader = accessToken
  ? {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    }
  : {};

export default AuthHeader;
