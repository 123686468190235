import React, { useEffect } from "react";
import { Container } from "@Styled/Login.style";
import SignInForm from "./Components/SignInForm";
import SignInSocial from "./Components/SignInSocial";
import { Footer } from "@Components";
import { LoadingUntilRedirect } from "@Components";

const Login = props => {
  const { isAuthenticated } = props;

  let html;
  if (isAuthenticated) {
    html = <LoadingUntilRedirect />;
  } else {
    html = (
      <Container>
        <SignInForm />
        {/* <SignInSocial /> */}
        <Footer />
      </Container>
    );
  }

  return html;
};

export default Login;
