// const isDevEnv =
//   process &&
//   process.env &&
//   process.env.NODE_ENV &&
//   process.env.NODE_ENV === "development";

// export const url = isDevEnv ? "http://34.67.108.24" : "http://34.67.108.24";

// export const rURL = "https://backend-two.katastic.com/api/v1";
export const rURL = "https://backend-two.katastic.com/api/v1";
// export const jURL = "https://backend-one.katastic.com/jointoo";
export const jURL = "https://backend-two.katastic.com/jointoo";
export const testURL = "http://192.168.0.219:8085/jointoo";
export const URL = "http://localhost:3000";

export const logout = () => {
  localStorage.removeItem("accessToken");
  window.top.location = "/";
};
