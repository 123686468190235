import React, { useState, useEffect } from "react";
import InvestTabs from "@Components/InvestTabs";
import InvestListHeader from "@Components/InvestListHeader";
import InvestHeader from "@Components/InvestHeader";
import SelectedInvestPreview from "@Components/SelectedInvestPreview";
import ProRating from "@Components/ArtistProfile/ProRating/ProRating";
import UserRating from "@Components/ArtistProfile/UserRating/UserRating";
import SalesHistory from "@Components/SalesHistory";
import InvestEndorsedBy from "@Components/InvestEndorsedBy";
import InvestInfo from "@Components/InvestInfo";
import InvestButton from "@Components/InvestButton";
import CustomModal from "@Components/CustomModal";
import BuyModalContent from "@Components/BuyModalContent";
import RightsBuyedModal from "@Components/RightsBuyedModal";
import { RESTjava, jURL } from "@Utils";
import { useRouter } from "@Hooks";
import lscache from "lscache";

import { artistProfileDetails } from "@Utils/mocks/artistProfileDetails.js";
import { artistListCarousel } from "@Utils/mocks/artistListCarousel.js";

import Grid from "@material-ui/core/Grid";
import { select } from "@storybook/addon-knobs";
import { getHistoryPathArray } from "@Helpers";
import download from "downloadjs";
import { createReturn } from "typescript";
import { useControlled } from "@material-ui/core";

const Invest = (props) => {
  const { history } = props;
  const Router = useRouter();
  const { triggerWalletUpdate, mimi, setPlayerMediaID } = props;
  const [artistProfile, setartistProfile] = useState(artistProfileDetails);
  const [endorsedBy, setEndorsedBy] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [selectedInvest, setSelectedInvest] = useState(null);
  const [userRating, setUserRating] = useState(0);
  const [proRating, setProRating] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rightsAmount, setRightsAmout] = useState(0);
  const [isCongratsModalVisible, setIsCongratsModalVisible] = useState(false);
  const [maxRightsAmountMessage, setMaxRightsAmountMessage] = useState(false);
  const [userRightsAmount, setUserRightsAmount] = useState(0);
  const [userShareProcent, setUserShareProcent] = useState(0);
  const [documentsList, setDocumentsList] = useState([]);

  const entrySLug = getHistoryPathArray(history);
  const id = entrySLug.slice(-1).toString();

  const router = useRouter();
  const selectedCategory = router.query.selectedCategory;
  const selectedTab = router.query.tab;
  console.log(props.location.state, "Robert")
  const getData = async (event) => {
    // await RESTjava.get(`/api/project/getProject?projectId=${id}`)
    //   .then((answer) => {
    //     var item = answer.data;
    //     var entry = {
    //       rightsOffered: item.numberOfUnitsSold,
    //       rightsSold: item.numberOfRightsSold,
    //       dueDate: item.dueDate,
    //       releaseDate: item.releaseDate,
    //       title: item.title,
    //       id: item.id,
    //       artistId: item.artist.id,
    //       artist: item.artist.name,
    //       rights: item.numberOfUnitsSold,
    //       share: item.percentage,
    //       offeringPrice: item.pricePerUnit,
    //       imgSrc: item.artist.imageUrl,
    //       videoId: "",
    //       info: item.info,
    //       projectType: item.category,
    //       link: item.link,
    //     };
    //     setProjectData(answer);

    //   })
    //   .catch((err) => {
    //     if (err && err.response && err.response.data) {
    //       const { message } = err.response.data;
    //     }
    //   });

    setSelectedInvest(props.location.state);
    setIsPageLoading(false);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const handlePlayButton = () => {
    var win = window.open(selectedInvest.link, "_blank");
    win.focus();
  };

  const handleBuyButton = () => {
    setIsModalVisible(true);
  };

  const handleBuyRights = async (event) => {
    //   // setIsCongratsModalVisible(true);
    setIsModalVisible(false);

    await RESTjava.post(
      `api/project/buyProjectRights?numberOfUnits=${rightsAmount}&projectId=${id}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        // triggerWalletUpdate(Math.random());
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
    getData();
    // }
  };

  const getAllDocuments = async () => {
    await RESTjava.get(`/api/project/getAllProjectDocuments?productId=${id}`)
      .then((answer) => {
        setDocumentsList(answer);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };
  useEffect(() => {
    getAllDocuments();
  }, []);

  const handleDownloadClick = () => {
    return documentsList.data.map((item, index) => {
      const urle = `https://katastic-java.wtzconsult.com/jointoo/api/project/getProjectDocument?documentFilename=${item.filename}&productId=${id}`;

      return fetch(urle)
        .then((response) => response.blob())
        .then((blob) => {
          download(blob, `${item.filename}`, `${item.extension}`);
        });
    });
  };
  if (isPageLoading) {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        {selectedCategory ? (
          <InvestTabs
            selectedTab={selectedTab}
            selectedCategory={selectedCategory}
          />
        ) : null}
        <InvestListHeader />
      </div>
    );
  } else {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        {selectedCategory ? (
          <InvestTabs
            selectedTab={selectedTab}
            selectedCategory={selectedCategory}
          />
        ) : null}
        <InvestListHeader />
        <SelectedInvestPreview
          id={selectedInvest.id}
          artistName={selectedInvest.artist}
          projectType={selectedInvest.projectType}
          rights={selectedInvest.rights}
          share={selectedInvest.share}
          offeringPrice={selectedInvest.offeringPrice}
          imgSrc={selectedInvest.imgSrc}
          rightsSold={selectedInvest.rightsSold}
          rightsOffered={selectedInvest.rightsOffered}
          dueDate={selectedInvest.dueDate}
          releaseDate={selectedInvest.releaseDate}
          projectTitle={selectedInvest.title}
        />
        <Grid container style={{ paddingTop: "20px" }}>
          {/* <InvestInfo
            // proRatingData={artistProfile}
            // userRatingData={artistProfile}
            investInfo={selectedInvest.info}
            proRating={proRating}
            userRating={userRating}
            rightsAmount={rightsAmount}
          /> */}
          <Grid item xs={1} />
          <Grid item xs={6}>
            {/* <InvestEndorsedBy data={projectData} /> */}
            {/* <SalesHistory data={salesData} /> */}
          </Grid>
        </Grid>
        <InvestButton
          handlePlayButton={handlePlayButton}
          handleBuyButton={handleBuyButton}
          trackLink={selectedInvest.link}
        />
        <div
          onClick={() => handleDownloadClick()}
          style={{
            backgroundColor: "black",
            width: "200px",
            height: "40px",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <p
            style={{
              marginLeft: "0",
              lineHeight: "40px",
              color: "white",
            }}
          >
            Download docs
          </p>
        </div>
        <CustomModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          buttonPressed={handleBuyRights}
          buttonText={"BUY AMOUNT"}
        >
          <BuyModalContent
            offeringPrice={selectedInvest.offeringPrice}
            rightsAmount={rightsAmount}
            setRightsAmout={setRightsAmout}
            maxRightsAmount={
              selectedInvest.rightsOffered - selectedInvest.rightsSold
            }
            maxRightsAmountMessage={maxRightsAmountMessage}
          />
        </CustomModal>
        <RightsBuyedModal
          open={isCongratsModalVisible}
          setOpen={setIsCongratsModalVisible}
          id={selectedInvest.id}
          artistName={selectedInvest.artist}
          projectType={selectedInvest.projectType}
          rights={userRightsAmount}
          share={userShareProcent}
          offeringPrice={selectedInvest.offeringPrice}
          rightsOffered={selectedInvest.rightsOffered}
        />
      </div>
    );
  }
};

export default Invest;
