// #region Global Imports
import CustomModal from "@Components/CustomModal";
import RightsBuyedModal from "@Components/RightsBuyedModal";
import TradeEntry from "@Components/TradeEntry";
import TradeEntryForListing from "@Components/TradeEntryForListing";
import TradeListHeader from "@Components/TradeListHeader";
import TradeOrderType from "@Components/TradeOrderType";
import TotalDisplay from "@Components/TradeOrderType/Components/TotalDisplay";
import Grid from "@material-ui/core/Grid";
import { RESTjava } from "@Utils";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "util";
import Chart from "./Components/Chart";
import PortfolioTable from './Components/PortfolioTable';
import TradeTable from './Components/TradeTable';
import { mockData } from './Components/dataJson';
import { mock2, mock1 } from './Components/dataJson';

const Trade = (props) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  // eslint-disable-next-line
  const [orderMarketTotal, setOrderMarketTotal] = useState(0);

  const [rightsValue, setRightsValue] = useState(0);
  const [selectedEntryPrice, setSelectedEntryPrice] = useState(0);
  const [maxRightsAmount, setMaxRightsAmount] = useState(0);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isCongratsModalVisible, setIsCongratsModalVisible] = useState(false);
  const [buyedInvestEntry, setBuyedInvestEntry] = useState({});
  const [isStopLimitModalVisible, setIsStopLimitModalVisible] = useState(false);
  const [selectedEntryForStopLimit, setSelectedEntryForStopLimit] = useState(
    isNullOrUndefined
  );
  const [
    isAddedToWatchlistModalVisible,
    setIsAddedToWatchlistModalVisible,
  ] = useState(false);
  const [selledModal, setSelledModal] = useState(false);
  const [chartLastValue, setChartLastValue] = useState(false);
  const [dataPortfolioList, setDataPortfolioList] = useState([]);

  const [martketAmount, setMarketAmount] = useState("");
  const [pricePerUnitAmount, setPricePerUnitAmount] = useState("");
  const [buyPrice, setBuyPrice] = useState(null);
  const [sellPrice, setSellPrice] = useState(null);

  const windowWidth = window.innerWidth;

  const getPortfolioData = async (event) => {
    await RESTjava.get(
      `api/project/getMyRightsForTrade?page=${0}&size=${100}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        var data = answer.data.content;

        setDataPortfolioList(data);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  const getData = async (event) => {
    await RESTjava.get(`api/project/getProjectsForTrade?page=${0}&size=${50}`)
      .then((answer) => {
        setDataList(answer.data.content);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  const getRightsValue = async (event) => {
    let projectNameString = "";
    if (dataPortfolioList.length) {
      dataPortfolioList.map((item) => {
        if (item.project.id === selectedEntry) {
          projectNameString = item.project.projectName;
        }
      });
    } else {
      dataList.map((item) => {
        if (item.id === selectedEntry) {
          projectNameString = item.project.projectName;
        }
      });
    }

    await RESTjava.get(
      `/api/project/getAcquiredProjectRights?projectName=${projectNameString}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        setRightsValue(answer.data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  useEffect(() => {
    getData();
    getPortfolioData();
  }, []);
  useEffect(() => {
    getRightsValue();
    console.log(
      "[Create Project] ",
      dataList &&
      dataList[selectedEntry] &&
      dataList[selectedEntry]["projectName"]
    );
  }, [selectedEntry]);

  const addToWatchlist = async (id) => {
    setIsAddedToWatchlistModalVisible(true);

    await RESTjava.post(
      `/api/invest/addToWatchlist?investID=${id}&userEmail=${lscache.get(
        "userEmail"
      )}`
    ).catch((err) => {
      if (err && err.response && err.response.data) {
        console.log(`Error:`, err)
      }
    });
  };

  const getChartData = async (id, offeringPrice, rights) => {
    setSelectedEntryPrice(offeringPrice);
    setMaxRightsAmount(rights - 100);
    setSelectedEntryId(id);
  };

  const handleBuy = async (amount) => {
    const toFixedBuyTotal = parseFloat(
      (amount * selectedEntryPrice).toFixed(2)
    );
    setOrderMarketTotal(toFixedBuyTotal);
    setSelledModal(false);

    await RESTjava.post(
      `api/project/orderBuyRightsFromTrade?numberOfUnits=${amount}&projectId=${selectedEntryId}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        getData();
        var data = answer.data;
        var invest = {
          id: data.invest.id,
          artistName: data.invest.artist.name,
          projectType: data.invest.projectType,
          rights: data.rights,
          share: data.share,
          offeringPrice: data.invest.offeringPrice,
          rightsOffered: data.invest.rightsOffered,
        };
        setBuyedInvestEntry(invest);
        setIsCongratsModalVisible(true);
        // triggerWalletUpdate(Math.random());
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  const handleSell = async (amount) => {
    setSelledModal(true);

    await RESTjava.post(
      `api/project/orderSellRightsForTrade?numberOfUnits=${amount}&projectId=${selectedEntryId}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        getData();
        var data = answer.data;
        var invest = {
          id: data.invest.id,
          artistName: data.invest.artist.name,
          projectType: data.invest.projectType,
          rights: data.rights,
          share: data.share,
          offeringPrice: data.invest.offeringPrice,
        };

        setBuyedInvestEntry(invest);
        setIsCongratsModalVisible(true);
        // triggerWalletUpdate(Math.random());
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  const handleStopLimit = async (upLimit, downLimit, amount, buy) => {
    await RESTjava.post(
      `/api/invest/addStopLimit?amount=${amount}&buy=${buy}&downLimit=${downLimit}&investID=${selectedEntryId}&upLimit=${upLimit}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        getData();
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  // const handleCloseModal = () => {
  //   setIsBuyModalVisible(false);
  // };
  const handleShowStopLimit = (id) => {
    var selectedEntryForStopLimitToSet = {};
    dataList.map((item) => {
      if (item.id === id) {
        selectedEntryForStopLimitToSet = item;
      }
    });
    setIsStopLimitModalVisible(true);
    setSelectedEntryForStopLimit(selectedEntryForStopLimitToSet);
  };

  const handleCancelOrder = async () => {
    await RESTjava.post(
      `/api/invest/removeStopLimit?investID=${selectedEntryForStopLimit.id
      }&userEmail=${lscache.get("userEmail")}`
    )
      .then((answer) => {
        // setIsStopLimitModalVisible(false);
        handleCloseStopLimitModal();
        getData();
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error:`, err)
        }
      });
  };

  const handleCloseStopLimitModal = () => {
    setIsStopLimitModalVisible(false);
  };

  const handleCloseWatchlistModal = () => {
    setIsAddedToWatchlistModalVisible(false);
  };

  const onSelectForFraction = (item) => {
    setChartLastValue(item);
  };



  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={"trade"}>
        <h1 className="trade-title" style={{ color: "#0F1321" }}>TRADE</h1>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={"lineChart"}>
              <Chart
                dataset={selectedEntryPrice}
                chartLastValue={chartLastValue}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <TradeOrderType
              handleBuy={handleBuy}
              handleStopLimit={handleStopLimit}
              handleSell={handleSell}
              price={selectedEntryPrice}
              maxAmount={maxRightsAmount}
              rightsValue={rightsValue}
              setMarketAmount={(event) => setMarketAmount(event)}
              setPricePerUnitAmount={(event) => setPricePerUnitAmount(event)}
              martketAmount={martketAmount}
              pricePerUnitAmount={pricePerUnitAmount}
              sellPrice={sellPrice}
              buyPrice={buyPrice}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className={`table_container`}>
          <p style={{ textTransform: "uppercase", marginLeft: 0, color: "#0F1321" }}>
            My Portfolio
          </p>

          {windowWidth > 500 ?
            <div className={`invest_table_contianer`}>
              <div className={`inv_table`}>
                <TradeListHeader />
                <div className={"tradeList"}>
                  {mock2.map((item, index) => (
                    <TradeEntry
                      key={index}
                      data={item}
                      addToWatchlist={addToWatchlist}
                      setSelectedEntry={setSelectedEntry}
                      selectedEntry={selectedEntry}
                      showChart={getChartData}
                      handleShowStopLimit={handleShowStopLimit}
                      onSelectForFraction={(item) => onSelectForFraction(item)}
                      setSellPrice={setSellPrice}
                      setBuyPrice={setBuyPrice}
                    />
                  ))}
                </div>
              </div>
            </div>
            :
            <div>
              {mock2.map((item, index) => (
                <PortfolioTable
                  key={index}
                  data={item}
                  addToWatchlist={addToWatchlist}
                  setSelectedEntry={setSelectedEntry}
                  selectedEntry={selectedEntry}
                  showChart={getChartData}
                  handleShowStopLimit={handleShowStopLimit}
                  onSelectForFraction={(item) => onSelectForFraction(item)}
                  setSellPrice={setSellPrice}
                  setBuyPrice={setBuyPrice}
                />
              ))}
            </div>
          }
        </Grid>

        <Grid item xs={12} className={`table_container`}>
          <p style={{ textTransform: "uppercase", marginLeft: 0, color: "#0F1321" }}>Trade</p>
          {windowWidth > 500 ?
            <div>
              <TradeListHeader forActiveTrades={true} />
              <div className={"tradeList"}>
                {mock1.map((item, index) => (
                  <TradeEntryForListing
                    key={index}
                    data={item}
                    addToWatchlist={addToWatchlist}
                    setSelectedEntry={setSelectedEntry}
                    selectedEntry={selectedEntry}
                    showChart={getChartData}
                    handleShowStopLimit={handleShowStopLimit}
                    onSelectForFraction={(item) => onSelectForFraction(item)}
                    setBuyPrice={setBuyPrice}
                    setSellPrice={setSellPrice}
                  />
                ))}
              </div>
            </div>
            :
            <div>
              {mock1.map((item, index) => (
                <TradeTable
                  key={index}
                  data={item}
                  addToWatchlist={addToWatchlist}
                  setSelectedEntry={setSelectedEntry}
                  selectedEntry={selectedEntry}
                  showChart={getChartData}
                  handleShowStopLimit={handleShowStopLimit}
                  onSelectForFraction={(item) => onSelectForFraction(item)}
                  setBuyPrice={setBuyPrice}
                  setSellPrice={setSellPrice}
                />
              ))}
            </div>
          }

        </Grid>

        <RightsBuyedModal
          open={isCongratsModalVisible}
          setOpen={setIsCongratsModalVisible}
          id={buyedInvestEntry.id}
          artistName={buyedInvestEntry.artistName}
          projectType={buyedInvestEntry.projectType}
          rights={buyedInvestEntry.rights}
          share={buyedInvestEntry.share}
          offeringPrice={buyedInvestEntry.offeringPrice}
          selled={selledModal}
          rightsOffered={buyedInvestEntry.rightsOffered}
        />
        <CustomModal
          open={isStopLimitModalVisible}
          setOpen={setIsStopLimitModalVisible}
          buttonPressed={handleCancelOrder}
          buttonText={"CANCEL ORDER"}
        >
          <div style={{ marginTop: -30 }}>
            {console.log("SELECTED: ", selectedEntryForStopLimit)}
            {selectedEntryForStopLimit.buy ? (
              <div
                className={"amountField"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                STOP LIMIT BUY
              </div>
            ) : (
              <div
                className={"amountField"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                STOP LIMIT SELL
              </div>
            )}
            <div
              className={"amountField"}
              style={{ display: "flex", flexDirection: "row" }}
            >
              STOP
              <TotalDisplay
                amount={selectedEntryForStopLimit.stopLimitUpLimit}
                currency={"$"}
              />
            </div>
            <div
              className={"amountField"}
              style={{ display: "flex", flexDirection: "row" }}
            >
              LIMIT
              <TotalDisplay
                amount={selectedEntryForStopLimit.stopLimitDownLimit}
                currency={"$"}
              />
            </div>
            <div
              className={"amountField"}
              style={{ display: "flex", flexDirection: "row" }}
            >
              QUANTITY
              <TotalDisplay
                amount={selectedEntryForStopLimit.stopLimitAmount}
                currency={""}
              />
            </div>
            <div
              className={"amountField"}
              style={{ display: "flex", flexDirection: "row" }}
            >
              TOTAL
              <TotalDisplay
                amount={selectedEntryForStopLimit.stopLimitTotal}
                currency={"$"}
              />
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={isAddedToWatchlistModalVisible}
          setOpen={setIsAddedToWatchlistModalVisible}
          buttonPressed={handleCloseWatchlistModal}
          buttonText={"DONE"}
        >
          <div>ADDED TO WATCHLIST</div>
        </CustomModal>
      </Grid>
    );
  }
};

export default Trade;
