import React, { useState, useEffect } from 'react';
import { rURL, REST } from '@Utils';

import { SideMenu } from '@Components/';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import AccountHeader from './Components/AccountHeader';
import TabsButtons from './Components/TabsButtons';
import UserDetails from './Components/UserDetails';
import ProfilePhoto from './Components/ProfilePhoto';
import DataField from './Components/DataField';
import SettingsPanel from './Components/SettingsPanel';
import LogOutButton from './Components/LogOutButton';
//import Wallet from './Components/Wallet';
///////////Account page
const AccountProfile = () => {
  return (
    <section id="account">
      {/*/////////////LEFT SIDE*/}
      <Grid container>
        <Grid item md={4} lg={4}>
          <div className={'Column--1'}>
            <AccountHeader />
            <TabsButtons />
            <UserDetails />
            {/* <Wallet /> */}
            <LogOutButton />
          </div>
        </Grid>
        <Grid item md={2} lg={2}>
          <div className={'ProfilePhotoContainer'}>
            <ProfilePhoto />
          </div>
        </Grid>
        {/*/////////////RIGHT SIDE*/}
        <Grid item md={6} lg={6}>
          <div className={'Column--2'}>
            <SettingsPanel />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default AccountProfile;
