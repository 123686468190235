import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
  categoryIcon,
  filtersIcon,
  basketIcon,
} from '@Helpers/ImageRoutes/ArtistDetails';


const MarketFilters = (props) => {

  const { handleApparel,  handleAccessories, handleGifts, handleFashion, handleAuto } = props

  const [categoryOpen, setCategoryOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const handleCategory = () => {
    setCategoryOpen(!categoryOpen);
  };

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const selectFilter = () =>{
    handleApparel()
  }

  const ExpandCategory = () => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', marginRight: '118px' }}
      >
        <div className={`expanded-category`} onClick={() => handleCategory()}>
          <img src={categoryIcon} className={'category-icon'} />
          <div className={'category-text'}>
            <h6 className={'btn-label'}>CATEGORY</h6>
            <ul>
              <li className={'category-list'} onClick={handleApparel}>APPAREL</li>
              <li className={'category-list'} onClick={handleAccessories}>ACCESSORIES</li>
              <li className={'category-list'} onClick={handleGifts}>GIFTS</li>
              <li className={'category-list'} onClick={handleFashion}>FASHION</li>
              <li className={'category-list'} onClick={handleAuto}>AUTO</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const CategoryBtn = () => {
    return (
      <div className={'category-btn'} onClick={() => handleCategory()}>
        <img src={categoryIcon} className={'category-icon'} />
        <div className={'category-text'}>
          <h6 className={'btn-label'}>CATEGORY</h6>
        </div>
      </div>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={6} className={'filters-container'}>
        {categoryOpen ? <ExpandCategory /> : <CategoryBtn />}

        {filterOpen ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: '96.6px',
            }}
          >
            <div className={'expanded-filters'} 
            // onClick={() => handleFilter()}
            >
              <img src={filtersIcon} className={'category-icon'} />
              <div className={'category-text'}>
                <h6 className={'btn-label'}>FILTERS</h6>
                <ul>
                  <li className={'category-list'}>RATING</li>
                  <li className={'category-list'}>PRICE</li>
                  <li className={'category-list'}>NEW</li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
            <div className={'filters-btn'} 
            // onClick={() => handleFilter()}
            >
              <img src={filtersIcon} className={'category-icon'} />
              <div className={'category-text'}>
                <h6 className={'btn-label'}>FILTERS</h6>
              </div>
            </div>
          )}

        {/* <div className={'basket-btn'}>
                    <img src={basketIcon} className={'basket-icon'} />
                </div> */}
      </Grid>
    </Grid>
  );
};

export default MarketFilters;
