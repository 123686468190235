import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { GlobalMUIStyles, CenterWrap } from '@Styled/AppStyle';
import { LoginMUIStyles } from '@Styled/Login.style';
import { LogoBtn, LogoRed, loginPlayDisabled } from '@Helpers/ImageRoutes/Logo';
import registerFormSchemaStep7 from '../../FormSchema/registerFormSchemaStep7';
import validate from 'validate.js';

const Step7Signup = props => {
  const { setNextStep, isVisible, secondFormState, setSecondFormState } = props;
  const GlobalStyle = GlobalMUIStyles({ color: 'cadetblue' });
  const LoginStyle = LoginMUIStyles();

  const [idType, setIdType] = useState(null);
  const [issuingAuthority, setIssuingAuthority] = useState(null);
  const [issueDate, setIssueDate] = useState(null);
  const [expiration, setExpiration] = useState(null);

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, registerFormSchemaStep7);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const handleNext = () => {
    setSecondFormState({
      ...secondFormState,
      step7: {
        idType: idType,
        issuingAuthority: issuingAuthority,
        issueDate: issueDate,
        expiration: expiration,
      },
    });
    setNextStep(8);
    console.log('[Step7]', secondFormState);
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems={`center`}
      justify={`center`}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <h1>Investor Sign-up</h1>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'idtype'}
          name='idtype'
          label={'ID Type'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-idtype',
            form: {
              autocomplete: 'off',
            },
          }}
         
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setIdType(value);
          }}
          error={hasError('idtype')}
          helperText={
            hasError('idtype') ? formState.errors.idtype[0] : null
          }
          value={formState.values.idtype || ''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'issuingauthority'}
          name='issuingauthority'
          label={'Issuing authority'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-issuing',
            form: {
              autocomplete: 'off',
            },
          }}
          
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setIssuingAuthority(value);
          }}
          error={hasError('issuingauthority')}
          helperText={
            hasError('issuingauthority') ? formState.errors.issuingauthority[0] : null
          }
          value={formState.values.issuingauthority || ''}
        />
      </Grid>
      <Grid item xl={8} lg={8} md={8} xs={8}>
        <TextField
          id={'issuedate'}
          name='issuedate'
          label={'Issue Date'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-isssuedate',
            form: {
              autocomplete: 'off',
            },
          }}
          
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setIssueDate(value);
          }}
          error={hasError('issuedate')}
          helperText={
            hasError('issuedate') ? formState.errors.issuedate[0] : null
          }
          value={formState.values.issuedate || ''}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} xs={4}>
        <TextField
          id={'expirationdate'}
          name='expirationdate'
          label={'Expiration'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-expiration',
            form: {
              autocomplete: 'off',
            },
          }}
      
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setExpiration(value);
          }}
          error={hasError('expirationdate')}
          helperText={
            hasError('expirationdate') ? formState.errors.expirationdate[0] : null
          }
          value={formState.values.expirationdate || ''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
      {formState.isValid ? (
          <label htmlFor="icon-button-file" className="nextButtonContainer">
            <IconButton
              color="primary" 
              component="span"
              onClick={handleNext}
              disabled={!formState.isValid}
              type="submit"
            >
              <img src={LogoBtn} />
            </IconButton>
          </label>
        ) : (
            <img src={loginPlayDisabled} />
        )}
      </Grid>
    </Grid>
  );
};

export default Step7Signup;
