import React from "react";
import { Grid } from "@material-ui/core";
import { hpgPlay } from "@Helpers/ImageRoutes/Assets";
import { ButtonPink } from "@Components";

import useStyles from "./hero.style";
import { useRouter } from "@Hooks";
import patratele from '../../../Assets/Images/Patratele.svg';

const Home = () => {
  const Router = useRouter();
  const classes = useStyles();

  const redirectToLogin = () => {
    Router.push("/login");
  };

  const redirectToRegister = () => {
    Router.push("/register");
  };

  return (
    <Grid container className={classes.hero}>
      {/* <Grid container spacing={4}> */}
      <Grid item lg={4} md={4} xl={4} xs={12} style={{ height: "100vh" }}>
        {/* <img className={`heroLogo`} src={hpgPlay} alt="" /> */}
        <img className={`heroLogo`} src={patratele} alt="" />
      </Grid>
      <Grid item lg={8} md={8} xl={8} xs={12}  >
        <h1 style={{ fontSize: 120, color: "#0F1321" }}>SLINGSHOT</h1>

        <div className={"hpgBtns"}>
          <ButtonPink
            onClick={redirectToLogin}
            variant={"contained"}
            color={"primary"}
            disableElevation
          >
            Login
          </ButtonPink>
          <ButtonPink
            onClick={redirectToRegister}
            variant={"contained"}
            color={"primary"}
            disableElevation
          >
            Register
          </ButtonPink>
        </div>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default Home;
