import React from "react";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import commaNumber from "comma-number";

const SelectedInvestPreview = (props) => {
  const {
    artistName,
    projectType,
    share,
    offeringPrice,
    imgSrc,
    rightsOffered,
    rightsSold,
    releaseDate,
    dueDate,
    projectTitle,
  } = props;

  return (
    <Grid container spacing={0} className={"selectedInvest"}>
      {/* <Grid item xs={1} className={"artistDetails"}>
        <img src={imgSrc} style={{ width: "65px", height: "65px" }} />
        <div className={"artistName"}>{artistName}</div>
      </Grid> */}
      <Grid item xs={1} className={"projectType"}>
        {projectTitle}
      </Grid>
      <Grid item xs={1} className={"projectType"}>
        {projectType}
      </Grid>

      <Grid item xs={1} className={"rights"}>
        {commaNumber(rightsOffered)}
      </Grid>
      <Grid item xs={1} className={"rights"}>
        {commaNumber(rightsSold)}
      </Grid>
      <Grid item xs={1} className={"share"}>
        {share} %
      </Grid>
      <Grid item xs={1} className={"offeringPrice"}>
        $ {commaNumber(offeringPrice)} / Right
      </Grid>
      <Grid item xs={1} className={"offeringPrice"}>
        {Moment(releaseDate).format("DD-MM-YYYY")}
      </Grid>
      <Grid item xs={1} className={"offeringPrice"}>
        {Moment(dueDate).format("DD-MM-YYYY")}
      </Grid>
    </Grid>
  );
};
export default SelectedInvestPreview;
