import React from 'react';

const MediaCarouselListItem = props => {
  const { item, navigateOn } = props;

  console.log('ID :', item);
  return (
    <div className={'MediaCarouselItem'} onClick={() => navigateOn(item.id)}>
      <div>
        <img src={item.mediaPhotoPAth} className="carouselImg" />
      </div>
      <div className="carouselName">{item.mediaContentName}</div>
    </div>
  );
};

export default MediaCarouselListItem;
