import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { theme as ThemeForMUI } from '@Styled/Theme';

export const GlobalMUIStyles = makeStyles(theme => ({
  textField: {
    [theme.breakpoints.down("md")]: {
    },
    width: `100%`,
    '& .MuiInputLabel-outlined': {
      color: "#0F1321",
    },
    '& .MuiInputBase-formControl': {
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '20px',
        borderColor: "#0F1321",
        borderWidth: '2px',
        '& legend': {
          color: "#0F1321",
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: "#0F1321",
      },
      '&:hover fieldset': {
        // borderColor: "#0F1321",
      },
      '& input': {
        color: "#0F1321",
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: '#0F1321"',
      },
    },
  },

  signUpField: {
    [theme.breakpoints.down("md")]: {
    },
    width: `20%`,
    '& .MuiInputLabel-outlined': {
      color: "#0F1321",
    },
    '& .MuiInputBase-formControl': {
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '20px',
        borderColor: "#0F1321",
        borderWidth: '2px',
        '& legend': {
          color: "#0F1321",
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: "#0F1321",
      },
      '&:hover fieldset': {
        // borderColor: "#0F1321",
      },
      '& input': {
        color: "#0F1321",
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: '#0F1321"',
      },
    },
  },

  modalTextInput: {
    '& label.Mui-focused': {},
    '& .MuiInput-underline:after': {
      borderBottomColor: '#050505',
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderBottomColor: '#0F1321',
      },
      '&:hover fieldset': {
        borderBottomColor: '#0F1321',
      },
      '&.Mui-focused fieldset': {
        borderBottomColor: '#0F1321',
        borderCollor: '#0F1321',
      },
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .&MuiOutlinedInput-input': {
      color: 'white',
    },
  },

  amountInput: {
    '& .MuiInputBase-input': {
      color: 'white',
      fontSize: '20px',
      fontFamily: "rooneysansbold",
      justifyContent: "flex-end",
      height: '30px'
    },
    '& .MuiInputBase-input:before': {
      color: 'white',
    },
    '& .&MuiOutlinedInput-input': {
      color: 'white',
    },
  },

  pinkButton: {
    background: "#0F1321",
    borderRadius: 50,
    color: "#F2F2F2",
    outline: `none`,
    borderColor: ThemeForMUI.colors.black,
    boxShadow: `0 0 10px ${"#0054E5"}`,
    minWidth: 250,
    textAlign: `center`,
    fontSize: 20,
    display: `block`,
    margin: props => (props.notCentered ? `` : `0 auto`),
  },
}));

export const CenterWrap = styled.div`
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
`;

export const CarouselWrap = styled.div`
  // max-width: 1400px;
  width: 100%;
  //margin-left: 2%;
`;

export const LinkCtaStyled = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  // font-family: ${({ theme }) => theme.fonts.rooney};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacings.scattered};
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
`;

export const LinkRegularStyled = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.rooney};
  font-size: ${({ theme }) => theme.fontSizes.body};
  cursor: pointer;
`;

export const NinputWrapper = styled.div``;
