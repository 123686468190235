import lscache from "lscache";
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

const RouteWithLayout = props => {
  const {
    layout: Layout,
    component: Component,
    location,
    needsAuth,
    title,
    ...rest
  } = props;

  const [redirect, setRedirect] = useState(
    needsAuth && !lscache.get("accessToken")
  );

  useEffect(() => {
    if (redirect) window.location.href = "/login";
  }, []);

  return (
    <Route
      {...rest}
      exact
      render={matchProps => {
        let routeOutput;
        if (redirect) {
          routeOutput = <>Loading...</>;
        } else {
          routeOutput = (
            <Layout location={location} pageTitleFromRouteConfig={title}>
              <Component {...matchProps} />
            </Layout>
          );
        }
        return routeOutput;
      }}
    />
  );
};

export default RouteWithLayout;
