import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Header from './Components/Header';

import StatementLine from './Components/StatementLine';
import StatementListHeader from './Components/StatementListHeader';
import { RESTjava } from '@Utils';
import lscache from 'lscache';
import moment from 'moment';

///////////Wallet page
const AccountWalletStatement = () => {
  const [statementData, setStatementData] = useState([]);
  const [isDaily, setIsDaily] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(null);

  const [totalAmountD, setTotalAmountD] = useState(0);
  const [totalAmountM, setTotalAmountM] = useState(0);
  const [currency, setCurrency] = useState('');

  const getData = async event => {
    if (isDaily) {
      await RESTjava.get(
        `/api/wallet/getStatements?period=` +
          `daily` +
          `&userEmail=${lscache.get('userEmail')}`
      )
        .then(answer => {
          //setTotalAmountD(0)
          var data = answer.data;
          console.log('GOT THE DATAS!!!!', answer);
          var statements = [];
          var balances = [];
          data.map(item => {
            var statementsEntry = {
              id: item.id,
              date: item.creationDate,
              inward: item.inward,
              inwardCurrency: item.currency,
              outward: item.outward,
              outwardCurrency: item.currency,
              balance: item.balance,
              balanceCurrency: item.currency,
            };
            statements.push(statementsEntry);
            balances.push(item.balance);
            setCurrency(item.currency);
          });
          setStatementData(statements);
          setTotalAmountD(balances.reduce((a, b) => a + b, 0));
          setIsPageLoading(false);
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log('ERROR DAILY:  ', message);
          }
        });
    }
    if (!isDaily) {
      await RESTjava.get(
        `/api/wallet/getStatements?period=` +
          `monthly` +
          `&userEmail=${lscache.get('userEmail')}`
      )
        .then(answer => {
          //setTotalAmountM(0)
          var data = answer.data;
          console.log('GOT THE DATAS!!!!', answer);
          var statements = [];
          var balances = [];
          data.map(item => {
            var statementsEntry = {
              id: item.id,
              date: item.creationDate,
              inward: item.inward,
              inwardCurrency: item.currency,
              outward: item.outward,
              outwardCurrency: item.currency,
              balance: item.balance,
              balanceCurrency: item.currency,
            };
            statements.push(statementsEntry);
            balances.push(item.balance);
            setCurrency(item.currency);
          });
          setStatementData(statements);
          setTotalAmountM(balances.reduce((a, b) => a + b, 0));
          setIsPageLoading(false);
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log('ERROR DAILY:  ', message);
          }
        });
    }
  };

  useEffect(() => {
    getData();
  }, [isDaily]);

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <section id="account">
        <Grid container className={'Container'} spacing={4}>
          <Header />

          <StatementLine
            isDaily={isDaily}
            setIsDaily={setIsDaily}
            totalAmountD={totalAmountD}
            totalAmountM={totalAmountM}
            currency={currency}
          />
          <StatementListHeader />
          {statementData.map(item => (
            <Grid container className={'AccountStatementEntry'}>
              <Grid
                xs={3}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <div className={'EntryDate'}>
                  {moment(item.date).format('DD-MM-YYYY')}
                </div>
              </Grid>
              <Grid
                xs={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className={'EntryValues'}>
                  {item.inward} {item.inwardCurrency}
                </div>
              </Grid>
              <Grid
                xs={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className={'EntryValues'}>
                  {item.outward} {item.outwardCurrency}
                </div>
              </Grid>
              <Grid
                xs={3}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <div className={'EntryValues'}>
                  {item.balance} {item.balanceCurrency}
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </section>
    );
  }
};

export default AccountWalletStatement;
