import React, { useEffect, useState } from 'react';
import WatchlisListHeader from '@Components/WatchlistListHeader';
import WatchlistEntry from '@Components/WatchlistEntry';

import { RESTjava } from '@Utils';
import lscache from 'lscache';
import { cancelOrder, cancelOrderWhite } from '@Helpers/ImageRoutes/Assets';
import WatchListTable from './Components/WatchListTable';

const InvestWatch = props => {
  const [watchlistData, setWatchlistData] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isCancelOrderButtonPressed, setIsCancelOrderButtonPressed] = useState(
    false
  );

  const screenWidth = window.innerWidth;

  const getData = async event => {
    await RESTjava.get(
      `/api/invest/getUserWatchlist?userEmail=${lscache.get('userEmail')}`
    )
      .then(answer => {
        var data = answer.data;
        var dataToShow = [];
        data.map(item => {
          console.log("ITEM: ", item)
          if (item.stopLimit.buy) {
            var watchlistEntry = {
              id: item.id,
              artist: item.invest.artist.name,
              buy: item.stopLimit.amount,
              sell: '-',
              limitPrice: item.stopLimit.downLimit,
              limitPriceCurrency: 'USD',
              marketPrice: item.invest.offeringPrice,
              marketPriceCurrency: item.invest.currency,
              imgSrc: item.invest.photoPath,
            };
            dataToShow.push(watchlistEntry);
          } else {
            var watchlistEntry = {
              id: item.id,
              artist: item.invest.artist.name,
              buy: '-',
              sell: item.stopLimit.amount,
              limitPrice: item.stopLimit.downLimit,
              limitPriceCurrency: 'USD',
              marketPrice: item.invest.offeringPrice,
              marketPriceCurrency: item.invest.currency,
              imgSrc: item.invest.photoPath,
            };
            dataToShow.push(watchlistEntry);
          }
        });
        setWatchlistData(dataToShow);
        setIsPageLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addCheckedFlagToData = () => {
    let data = watchlistData.map(item => {
      let obj = { ...item };
      obj.isChecked = false;
      return obj;
    });

    setWatchlistData(data);
  };

  const handleCheck = rowId => {
    const newDataWatchListIsChecked = watchlistData.map((item, index) => {
      if (item.id === rowId) {
        item.isChecked = !item.isChecked;
      }
      return item;
    });

    setWatchlistData(newDataWatchListIsChecked);
  };

  const removeFromPlaylist = async id => {
    await RESTjava.post(
      `/api/invest/removeFromWatchlist?ids=${Number(
        id
      )}&userEmail=${lscache.get('userEmail')}`
    )
      .then(answer => getData())
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log('ERR: ', message);
        }
      });
  };

  const handleCancelOrderButtonPressed = async => {
    if (isCancelOrderButtonPressed) {
      setIsCancelOrderButtonPressed(false);

      var ids = [];
      ids = watchlistData.map(item => {
        if (item.isChecked) {
          console.log('TRUE: ', item.isChecked, ' ', item.id);
          return item.id;
        }
      });

      console.log(ids);

      ids.map(item => {
        if (typeof item == 'undefined') {
          console.log('UNDEFINED DETECTED');
        } else {
          removeFromPlaylist(item);
        }
      });

      getData();
    } else {
      addCheckedFlagToData();
      setIsCancelOrderButtonPressed(true);
    }
  };

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <div className={'investWatchlist'}>
        <div className={'investHeader'}>
          <h1>WATCH LIST</h1>
          <div className={'investSubtitle'}>
            <h5>PENDING ORDERS</h5>
          </div>
        </div>

        {screenWidth > 500 ?
          <div>
            <WatchlisListHeader />
            {watchlistData.map(item => (
              <WatchlistEntry
                id={item.id}
                artist={item.artist}
                buy={item.buy}
                sell={item.sell}
                limitPrice={item.limitPrice}
                limitPriceCurrency={item.limitPriceCurrency}
                marketPrice={item.marketPrice}
                marketPriceCurrency={item.marketPriceCurrency}
                imgSrc={item.imgSrc}
                isChecked={item.isChecked}
                handleCheck={handleCheck}
              />
            ))}
          </div>
          :
          <div>
            {watchlistData.map(item => (
              <WatchListTable
                id={item.id}
                artist={item.artist}
                buy={item.buy}
                sell={item.sell}
                limitPrice={item.limitPrice}
                limitPriceCurrency={item.limitPriceCurrency}
                marketPrice={item.marketPrice}
                marketPriceCurrency={item.marketPriceCurrency}
                imgSrc={item.imgSrc}
                isChecked={item.isChecked}
                handleCheck={handleCheck}
              />
            ))}
          </div>
        }

        {/* <img 
                    onClick={() => handleCancelOrderButtonPressed()}
                    src={isCancelOrderButtonPressed ? cancelOrderWhite : cancelOrder}
                    style={{position: "absolute" , bottom: "100px", right: "100px"}}
                /> */}
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => handleCancelOrderButtonPressed()}
          src={isCancelOrderButtonPressed ? cancelOrderWhite : cancelOrder}
          style={{ position: 'absolute', bottom: '100px', right: '100px' }}
        />
      </div>
    );
  }
};

export default InvestWatch;
