import React, { useState, useEffect } from "react";

import { TextField, Grid, Select, MenuItem, Input } from "@material-ui/core";
import CustomModal from "@Components/CustomModal";
import comafy from "../../../../Helpers/comafy";
import NumberFormat from "react-number-format";

export const Intro = (props) => {
  const {
    currentStep,
    setCurrentStep,
    handleChange,
    formState,
    setFormState,
    setSlingshotType,
  } = props;
  const [modalIsVisible, setIsModalVisible] = useState(false);

  const screenWidth = window.innerWidth;

  const handleModal = () => {
    if (formState.values["estimatedValue"] >= 100000) {
      setIsModalVisible(true);
    } else if (formState.values["estimatedValue"]) {
      setSlingshotType("OTC");
      setCurrentStep("firstStep");
    }
  };

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handleOTC = () => {
    setIsModalVisible(false);
    setSlingshotType("OTC");
    setCurrentStep("firstStep");
  };
  const handleONE = () => {
    setIsModalVisible(false);
    setSlingshotType("ONE");
    setCurrentStep("firstStep");
  };

  return (
    <Grid container className="createProjectIntroContainer">
      <Grid item xs={12} className="titleContainer">
        <div className="titleText">Create Project</div>
      </Grid>

      <Grid item xs={12} className="contentContainer">
        <p className="inputTitle">What is your estimated value</p>

        <NumberFormat
          thousandSeparator={true}
          className="nrField"
          id="estimatedValue"
          name="estimatedValue"
          placeholder="e.g. $ 100,000"
          prefix={"$ "}
          value={formState.values["estimatedValue"]}
          onValueChange={(values) => {
            const { formattedValue, value, floatValue } = values;

            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                estimatedValue: floatValue,
              },
              touched: {
                ...formState.touched,
                estimatedValue: true,
              },
            }));
          }}
        />
      </Grid>

      <Grid
        xs={12}
        className={
          formState.values["estimatedValue"]
            ? "buttonContainer"
            : "buttonContainerDisabled"
        }
      >
        <div
          //   onClick={() => }
          onClick={handleModal}
          className={
            formState.values["estimatedValue"]
              ? "buttonTextContainer"
              : "buttonTextContainerDisabled"
          }
        >
          <p className="buttonText">Next</p>
        </div>
      </Grid>

      {modalIsVisible ? (
        <CustomModal
          open={modalIsVisible}
          setOpen={setIsModalVisible}
          buttonPressed={handleOTC}
          buttonText="NAFRAM OTC"
          closeCloseButton={true}
          showSecondButton={true}
          secondButtonPressed={handleONE}
          secondButtonText="NAFRAM ONE"
        >
          <div>
            {/* <h1 style={{ textAlign: 'center' }}> Pa</h1> */}
            {screenWidth > 500 ?
              <h4
                style={{
                  color: "#dbd9d3",
                  textAlign: "center",
                  marginTop: screenWidth > 500 ? "10px" : 50,
                  width: "250px"
                }}
              >
                Choose your project type
              </h4>
              :
              <h5
                style={{
                  color: "#dbd9d3",
                  textAlign: "center",
                  marginTop: screenWidth > 500 ? "50px" : 80,
                }}
              >
                Choose your project type
              </h5>
            }
          </div>
        </CustomModal>
      ) : null}
    </Grid>
  );
};

export default Intro;
