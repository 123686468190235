import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { LogoBtn, LogoRed, loginPlayDisabled } from '@Helpers/ImageRoutes/Logo';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { GlobalMUIStyles, CenterWrap } from '@Styled/AppStyle';
import { LoginMUIStyles } from '@Styled/Login.style';
import registerFormSchemaStep5 from '../../FormSchema/registerFormSchemaStep5';
import validate from 'validate.js';

const Step5Signup = props => {
  const { setNextStep, isVisible, secondFormState, setSecondFormState } = props;
  const GlobalStyle = GlobalMUIStyles({ color: 'cadetblue' });
  const LoginStyle = LoginMUIStyles();

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [citizzenship, setCitizenship] = useState(null);

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, registerFormSchemaStep5);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;





  const handleNext = () => {
    setSecondFormState({...secondFormState,  step5: {
      firstName: firstName,
      lastName: lastName,
      citizenship: citizzenship
 }})
 setNextStep(6)
    console.log('[Step5]', secondFormState);
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems={`center`}
      justify={`center`}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <h1>Investor Sign-up</h1>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'firstname'}
          name='firstname'
          label={'First name'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-firstname',
            form: {
              autocomplete: 'off',
            },
            
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setFirstName(value);
          }}
          error={hasError('firstname')}
          helperText={
            hasError('firstname') ? formState.errors.firstname[0] : null
          }
          value={formState.values.firstname || ''}
         
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'lastname'}
          name='lastname'
          label={'Last name'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-lastname',
            form: {
              autocomplete: 'off',
            },
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setLastName(value);
          }}
          error={hasError('lastname')}
          helperText={
            hasError('lastname') ? formState.errors.lastname[0] : null
          }
          value={formState.values.lastname || ''}
         
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'citizenship'}
          label={'Citizenship'}
          name='citizenship'
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-citizenship',
            form: {
              autocomplete: 'off',
            },
          }}
          
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setCitizenship(value);
          }}
          error={hasError('citizenship')}
          helperText={
            hasError('citizenship') ? formState.errors.citizenship[0] : null
          }
          value={formState.values.citizenship || ''}
         
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        {formState.isValid ? (
          <label htmlFor="icon-button-file" className="nextButtonContainer">
            <IconButton
              color="primary" 
              component="span"
              onClick={handleNext}
              disabled={!formState.isValid}
              type="submit"
            >
              <img src={LogoBtn} />
            </IconButton>
          </label>
        ) : (
            <img src={loginPlayDisabled} />
        )}
  
      </Grid>
    </Grid>
  );
};

export default Step5Signup;
