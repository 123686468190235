import React, { useState } from "react";
import { Button, List } from "@material-ui/core";
import { useRouter } from "@Hooks";

const TabsButtons = props => {
  const Router = useRouter();
  const { selectedTab, setSelectedTab } = props;

  return (
    <div className={"playlistButtonsContainer"}>
      {selectedTab == "PROFILE" ? (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/account/profile")}
        >
          PROFILE
        </div>
      ) : (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/account/profile")}
        >
          PROFILE
        </div>
      )}
      &nbsp;&nbsp;&nbsp;
      {selectedTab == "WALLET" ? (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/account/wallet")}
        >
          WALLET
        </div>
      ) : (
        <div
          className={"unselectedTabStyle"}
          onClick={() => (window.location.href = "/account/wallet")}
        >
          WALLET
        </div>
      )}
    </div>
  );
};

export default TabsButtons;
