import React from 'react';

export const CustomUtilityBtn = props => {
  return (
    <div
      className="customBtnBorder"
      style={{
        borderRadius: `${props.borderRadius}`,
        borderWidth: `${props.borderWidth}`,
        borderColor: `${props.borderColor}`,
        backgroundColor: `${props.backgroundColor}`,
        color: `${props.color}`,
        justifyContent: `${props.justifyContent}`,
        textAlign: `${props.textAlign}`,
        alignSelf: `${props.alignSelf}`,
        display: `${props.display}`,
        width: `${props.width}`,
        height: `${props.height}`,
        marginLeft: `${props.marginLeft}px`,
        marginRight: `${props.marginRight}px`,
        marginTop: `${props.marginTop}px`,
        marginBottom: `${props.marginBottom}px`,
        position: `${props.position}`,
        top: `${props.top}`,
        bottom: `${props.bottom}`,
        left: `${props.left}`,
        right: `${props.right}`,
      }}
    >
      <img
        src={props.icon}
        className="imageBtn"
        style={{
          transform: `rotate(${props.rotateIcon}deg)`,
          marginLeft: `${props.marginLeftIcon}px`,
          marginRight: `${props.marginRightIcon}px`,
          marginTop: `${props.marginTopIcon}px`,
          marginBottom: `${props.marginBottomIcon}px`,
          width: `${props.widthIcon}`,
          height: `${props.heightIcon}`,
          justifyContent: `${props.justifyContentIcon}`,
          textAlign: `${props.textAlignIcon}`,
          alignSelf: `${props.alignSelfIcon}`,
          backgroundColor: `${props.backgroundColorIcon}`,
          color: `${props.colorIcon}`,
          display: `${props.displayIcon}`,
          tintColor: `${props.tintColorIcon}`,
        }}
      />
    </div>
  );
};
