import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { Plus, Minus } from '@Helpers/ImageRoutes/Market';
import CustomModal from '@Components/CustomModal';

const BuyTicket = props => {
  const [modalIsVisible, setIsModalVisible] = useState(false);
  const { eventData } = props;
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (eventData !== undefined) {
      setIsLoading(false);
      setTotal(eventData.ticketPrice);
    }
  }, [eventData]);
  
  const handleModal = () => {
    setIsModalVisible(true);
  };
  const save = () => {
    setIsModalVisible(false);
  };
  const incrementQuantity = () => {
    var tickets = quantity + 1;
    setQuantity(tickets);
  };
  const decrementQuantity = () => {
    var tickets = quantity - 1;
    setQuantity(tickets);
  };

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={'buyTicket-root'}>
        {console.log('event data ..... >>>', eventData)}
        <Grid item xs={12} md={12} lg={12} xl={12} className={'cart-body'}>
          <Grid item xs={12} md={12} lg={12} xl={12} className={'cart-item'}>
            <img src={eventData.eventPhotoPath} className={'item-img'} />
          </Grid>
          <Grid
            item
            xs={10}
            md={10}
            lg={10}
            xl={10}
            className={'info-container'}
          >
            <div className={'top-half'}>
              <div className={'item-info'}>
                <h3 className={'product-name'}>Ticket</h3>
                <h5>{eventData.ticketPrice} USD</h5>
              </div>

              <div className={'produc-quantity'}>
                <h4 className={'product-name'}>Pieces</h4>
                <label>{quantity}</label>
              </div>

              <div className={'incrementer'}>
                {/* <h1 className={'incermenter-btn'}>+</h1>
                            <h1 className={'incermenter-btn'}>-</h1> */}
                <img
                  src={Minus}
                  alt="minus"
                  className={'incermenter-btn'}
                  onClick={decrementQuantity}
                />
                <img
                  src={Plus}
                  alt="plus"
                  className={'incermenter-btn'}
                  onClick={incrementQuantity}
                />
              </div>
            </div>

            <div className={'bottom-half'}>
              <h6 className={'currency-symbol'}>TOTAL</h6>
              <div className={'amountInput'}>
                <div className={'amount'}>
                  <p>{total * quantity}</p>
                </div>
                <div className={'currency-label'}>
                  <h6 className={'currency-symbol'}>$</h6>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={10} md={10} lg={10} xl={10} className={'cart-footer'}>
            <div className={'checkout-btn'} onClick={handleModal}>
              <h6 className={'text-btn'}>CHECK OUT</h6>
            </div>

            {modalIsVisible ? (
              <CustomModal
                open={modalIsVisible}
                setOpen={setIsModalVisible}
                buttonPressed={save}
                buttonText={'OK'}
                closeCloseButton={true}
              >
                <div>
                  <h1 style={{ textAlign: "center"}}> CONGRATULATIONS</h1>

                  <h4 style={{color: '#FF3366', textAlign: "center", marginTop: "50px"}}>TICKETS BOUGHT</h4>
                </div>
              </CustomModal>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
export default BuyTicket;
