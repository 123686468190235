import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MarketFilters from "../market/Components/Filters/MarketFilters";

import Services from "./Components/Services/Services";
import { marketItemList } from "@Utils/mocks/marketItemsList";
import MerchList from "./Components/MerchList/MerchList";
import Banner from "./Components/Banner/Banner";
import { RESTjava } from "@Utils";
import { useRouter } from "@Hooks";

export const MarketServices = () => {
  const Router = useRouter();
  const [marketItems, setMarketItems] = useState(marketItemList);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getServices = async () => {
    await RESTjava.get(`/api/market/service/getAllServices?page=0&size=100`)
      .then(answer => {
        var data = answer.data.content;
        setServices(data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const goToCategory = category => {
    // Router.push({
    //   pathname: `/${category}`
    //   // query: { tab: tab, selectedCategory: selectedCategory },
    // });

    window.location.href = `/${category}`;
  };

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={"services-root"}>
        {/* <Grid item xs={12} md={12} lg={12} xl={12}>
        <MarketFilters />
      </Grid> */}

        <Grid item xs={12} md={12} lg={12} xl={12} className={"services-body"}>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <Services />
          </Grid>

          <Grid item xs={6} md={6} lg={6} xl={6}>
            <Banner />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={"bottom-container"}
        >
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("label")}
            >
              <div>
                <img
                  src="https://d3ctd91c7nw0ql.cloudfront.net/damfiles/default/global/performance-production/applications/podcasting/motiv_mv51_podcaster-v1_1920x1800-08_11_2018.jpg-63e7b6c3e74ba8a6cd83a2fa59c3d040.jpg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  }}
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>LABEL</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("eventOrganizer")}
            >
              <div>
                <img
                  src="https://d3vhc53cl8e8km.cloudfront.net/hello-staging/wp-content/uploads/2017/12/22223742/Events-1200x630.jpg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    resizeMode: "contain"
                  }}
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                EVENT ORGANIZER
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("filmStudio")}
            >
              <div>
                <img
                  src="https://i.pinimg.com/originals/92/5d/63/925d63ad48dff726b775d50a49bd0a8c.jpg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  }}
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                FILM STUDIO
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("producer")}
            >
              <div>
                <img
                  src="https://www.larpmusic.com/wp-content/uploads/2017/04/granny.jpg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  }}
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                PRODUCER
              </div>
            </div>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={12} lg={12} xl={12} className={'bottom-container'}>
          <MerchList services={services} />
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12} className={'bottom-container'}>
          <MerchList services={services} />
        </Grid> */}
      </Grid>
    );
  }
};

export default MarketServices;
