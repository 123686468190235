import React from "react";
import Grid from "@material-ui/core/Grid";
import { LoginMUIStyles } from "@Styled/Login.style";
import { ButtonPink } from "@Components";
import { useRouter } from "@Hooks";
import lscache from "lscache";

const Step999Signup = props => {
  const Router = useRouter();
  const { isVisible, setNextStep } = props;
  const LoginStyle = LoginMUIStyles();

  const redirectToProfile = () => {
    lscache.set("mediaID", null);
    Router.push("/account/profile");
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justify="center"
      direction="column"
      className={LoginStyle.root}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <Grid item xs={12} className="mt-3-rem">
        <h1>Congratulations!</h1>
        <h2>You've succesfully signed up!</h2>
      </Grid>

      <Grid item xs={12}>
        <ButtonPink
          onClick={redirectToProfile}
          variant={"contained"}
          color={"primary"}
          disableElevation
        >
          Go to Profile
        </ButtonPink>
      </Grid>
    </Grid>
  );
};

export default Step999Signup;
