import React, { useState, useEffect, useRef } from "react";
import InvestTabs from "@Components/InvestTabs";
import InvestHeader from "@Components/InvestHeader";
import InvestList from "@Components/InvestList";
import { RESTjava } from "@Utils";
import Grid from "@material-ui/core/Grid";

export const InvestAll = (props) => {
  const [investList, setInvestList] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState("CATEGORIES");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const isSubscribed = useRef(true);

  const getData = async () => {
    // await RESTjava.get(`api/project/getAllIproProjects?page=0&size=100`)
    //   .then((answer) => {
    //     if (!isSubscribed.current) return null;
    //     console.log("ANSWER: ", answer);
    //     var data = answer.data.content;
    //     var list = [];
    //     data.map((item) => {
    //       var entry = {
    //         rightsOffered: item.numberOfUnitsSold,
    //         rightsSold: item.numberOfRightsSold,
    //         dueDate: item.dueDate,
    //         releaseDate: item.releaseDate,
    //         tourFromDate: item.start,
    //         tourToDate: item.end,
    //         id: item.id,
    //         artistId: item.artist.id,
    //         title: item.title,
    //         artist: item.artist.name,
    //         rights: item.numberOfUnitsSold,
    //         share: item.percentage,
    //         offeringPrice: item.pricePerUnit,
    //         imgSrc: item.artist.imageUrl,
    //         videoId: "",
    //         projectType: item.category,
    //         value: item.estimatedValue,
    //       };
    //       list.push(entry);
    //     });
    //     console.log("LIST: ", list);
    //     setInvestList(list);
    //     setIsPageLoading(false);
    //   })
    //   .catch((err) => {
    //     if (err && err.response && err.response.data) {
    //       const { message } = err.response.data;
    //     }
    //   });
    const response = await fetch("https://robert-343f5-default-rtdb.europe-west1.firebasedatabase.app/properties.json");

    if (response.ok) {
      console.log("A raspuns");
    }
    const rest = await response.json();

    // console.log(rest, "Robert")
    var list = [];
    for (const key in rest) {

      var entry = {
        rightsOffered: rest[key]?.numberOfUnitsSold,
        rightsSold: rest[key]?.numberOfUnitsSold,
        dueDate: rest[key]?.dueDate,
        releaseDate: rest[key]?.releaseDate,
        tourFromDate: rest[key]?.start,
        tourToDate: rest[key]?.end,
        id: "1",
        artistId: "1",
        title: rest[key]?.title,
        artist: "name",
        rights: rest[key]?.numberOfUnitsSold,
        share: rest[key]?.percentage,
        offeringPrice: rest[key]?.pricePerUnit,
        imgSrc: "",
        videoId: "",
        projectType: rest[key]?.projectType,
        value: rest[key]?.estimatedValue,
      };
      list.push(entry);


    };
    setInvestList(list);
    setIsPageLoading(false);
  }
  console.log(investList, "<<<<INVEST")

  useEffect(() => {
    getData();
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  if (isPageLoading) {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        <InvestTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
    );
  } else {
    return (
      <Grid container className={"investPage"}>
        <Grid item xs={12}>
          <InvestHeader />
        </Grid>
        <Grid item xs={12}>
          <InvestTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </Grid>
        <Grid item xs={12}>
          <InvestList
            list={investList}
            tab={selectedTab}
            selectedCategory={selectedCategory}
          />
        </Grid>
      </Grid>
    );
  }
};

export default InvestAll;
