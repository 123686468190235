import List from '@material-ui/core/List';
import React from 'react';
import { routes, artistRoutes } from './links';
import {
  ShowHeadLink,
  ShowPrimaryLink,
  ShowSecondaryLink
} from './ShowLinkTypes';
import lscache from "lscache";
import { closeButton, SlingshotLogoForModal } from "@Helpers/ImageRoutes/Assets";
export const SideMenu = props => {
  const { currentPage, asPath } = props;

  let activeRoutes = null;

  console.log(lscache.get("userType"), "<Robert>>>>>>>>")
  if (lscache.get("userType") === "PropertyOwner") {
    activeRoutes = artistRoutes;
  } else {
    activeRoutes = routes;
  }
  const screenWidth = window.innerWidth;

  return (
    // <CenterWrap className={`menuDrawer`}>

    <div className={`menuDrawer`}>
      <div style={{ width: "300px" }}>
        <img
          src={SlingshotLogoForModal}
          style={{
            width: screenWidth > 500 ? "230px" : "100px",
            height: screenWidth > 500 ? "40px" : "70px",
            marginTop: screenWidth > 500 ? "60px" : 0,
            marginLeft: screenWidth > 500 ? 30 : 20,
            marginBottom: "50px",
            marginRight: "50px"
          }}
        />
      </div>
      <List>
        {activeRoutes.map((item, index) => {
          switch (item.type) {
            case 'head':
              return (
                <ShowHeadLink
                  item={item}
                  key={index}
                  index={index}
                  currentPage={currentPage}
                  asPath={asPath}
                />
              );
            case 'primary':
              return (
                <ShowPrimaryLink
                  item={item}
                  key={index}
                  index={index}
                  currentPage={currentPage}
                  asPath={asPath}
                />
              );
            case 'secondary':
              return (
                <ShowSecondaryLink
                  item={item}
                  index={index}
                  key={index}
                  currentPage={currentPage}
                  asPath={asPath}
                />
              );
          }
        })}
      </List>
    </div>
    // </CenterWrap> 
  );
};

// export default SideMenu;
