import React from "react";
import Grid from "@material-ui/core/Grid";

const TradeListHeader = (props) => {
  const {
    forActiveTrades,
  } = props;

  return forActiveTrades ? (
    <Grid container className={"tradeListHeader"}>
      <Grid item xs={2} style={{ textAlign: "start" }} className="table-header">
        ASSET
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }} className="table-header">
        PROJECT NAME
      </Grid>
      {/* <Grid item xs={1} style={{ textAlign: "center" }} className="table-header">
        PROJECT TYPE
      </Grid> */}
      <Grid item xs={1} style={{ textAlign: "center" }} className="table-header">
        CODE
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }} className="table-header">
        NR OF UNITS
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }} className="table-header">
        BUY
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }} className="table-header">
        SELL
      </Grid>

      <Grid item xs={1} style={{ textAlign: "end" }}></Grid>
    </Grid>
  ) : (
    <Grid container className={"tradeListHeader"}>
      <Grid item xs={2} style={{ textAlign: "start" }} className="table-header">
        ASSET
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }} className="table-header">
        PROJECT NAME
      </Grid>
      {/* <Grid item xs={1} style={{ textAlign: "center" }} className="table-header">
        PROJECT TYPE
      </Grid> */}
      <Grid item xs={1} style={{ textAlign: "center" }} className="table-header">
        CODE
      </Grid>
      {/*<Grid item xs={2} style={{ textAlign: 'center' }}>*/}
      {/*  PAR/BUY/SELL*/}
      {/*</Grid>*/}
      {/* <Grid item xs={1} style={{ textAlign: "center" }} className="table-header">
        IRO PRICE
      </Grid> */}
      <Grid item xs={2} style={{ textAlign: "center" }} className="table-header">
        BUY
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }} className="table-header">
        SELL
      </Grid>
      <Grid item xs={1} style={{ textAlign: "end" }}></Grid>
    </Grid>
  );
};

export default TradeListHeader;
