import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import BuyTicket from "./Components/Cart/BuyTicket";
import ItemInfo from "./Components/Cart/ItemInfo";
import { RESTjava } from "@Utils";
import EventModal from "./Components/Modal/EventModal";
import { IconButton } from "@material-ui/core";
import { backBtn } from "@Helpers/ImageRoutes/Market";
import { useRouter } from "@Hooks";

export const EventsCart = props => {
  const Router = useRouter();

  const { navigateOn } = props;

  const [eventData, seteventData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModatVisible] = useState(false);

  const router = useRouter();
  const { id } = router.query;

  const getData = async event => {
    await RESTjava.get(`/api/event/getEvent?eventID=${router.query.id}`)
      .then(answer => {
        var data = answer.data;
        seteventData(data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };

  useEffect(() => {
    if (router.query.id) {
      getData();
    }
  }, [router.query.id]);

  const handleModal = () => {
    setModatVisible(true);
  };

  const save = () => {
    setModatVisible(false);
  };

  const goToEvents = () => {
    Router.push({
      pathname: `/market/events`
    });
  };

  return (
    <Grid container className={"eventCart-root"}>
      <Grid item xs={12} md={12} lg={12} xl={12} className={"eventCard-header"}>
        <div className={"header-body"} onClick={() => goToEvents()}>
          <img src={backBtn} alt="backBtn" className={"backBtn"} />
          <h1 className={"title"}>POPULAR EVENTS</h1>
        </div>
      </Grid>

      <Grid item xs={6} md={6} lg={6} xl={6}>
        {eventData && (
          <BuyTicket eventData={eventData} handleModal={handleModal} />
        )}
      </Grid>
      <Grid item xs={6} md={6} lg={6} xl={6}>
        {eventData && <ItemInfo eventData={eventData} />}
      </Grid>
    </Grid>
  );
};
export default EventsCart;
