import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useRouter } from "@Hooks";

export const ShowHeadLink = props => {
  const { item, index, asPath } = props;
  const router = useRouter();

  const redirect = () => {
    window.location.href = item.link;
  };

  return (
    <div>
      <ListItem
        button
        onClick={redirect}
        style={item.listItemExtraStyle}
        key={`menu-link-${index}`}
        className={item.link === asPath ? "active" : ""}
      >
        <img src={item.icon} style={item.iconExtraStyle} />
        <div>
          <ListItemText
            className={`listItem ${item.listItemClassName}`}
            primary={item.text}
            style={item.textExtraStyle}
            key={`menu-link-text-${index}`}
          />
        </div>
      </ListItem>

      {item.hasDivider && <Divider variant="middle"></Divider>}
    </div>
  );
};
