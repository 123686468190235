import React from 'react';

import { buyBtnIcon } from '@Helpers/ImageRoutes/ArtistDetails';
import { Plus } from '@Helpers/ImageRoutes/Market';

const MarketItem = props => {
  const { item } = props;

  const addToCart = () => {
    //de adaugat linkul venit din BE prin item

    window.open(
      'https://sizeer.ro/nike-jordan-max-aura-barbati-pantofi-sport-albastru-aq9084-401?gclid=Cj0KCQiAhs79BRD0ARIsAC6XpaX7wQDaR70mQVMBhha8nzXUT6grtcvrXsay6FybnNZ5Z5_spwaESbYaApj4EALw_wcB'
    );
  };

  return (
    <div className={'market-item'}>
      <div
        className={'item'}
        onClick={() => console.log('YOU CLICKED ON ', item.name)}
      >
        <div>
          <img src={item.productPhotoPath} className={'item-img'} />
        </div>
        <div className={'info-container'}>
          <div className={'info-text'}>
            <h4>{item.productName}</h4>
            <h6>
              {item.price} {item.currency}
            </h6>
          </div>
          <div className={'buy-btn'}>
            <img src={Plus} alt="plus" className={'btn'} onClick={addToCart} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketItem;
