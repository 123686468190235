import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PopularEvents from "./Components/PopularEvents";
import UpcomingEvents from "./Components/UpcomingEvents";
import { REST, RESTjava, rURL } from "@Utils";
import { useRouter } from "@Hooks";

export const MarketEvents = () => {
  const Router = useRouter();
  const [popularEvents, setPopularEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getUpcoming = async event => {
    await RESTjava.get(`/api/event/getUpcomingEvents`).then(answer => {
      var data = answer.data;
      setUpcomingEvents(data);
      // console.log(data)
      setIsPageLoading(false);
    });
  };

  const getPopularEvents = async event => {
    await RESTjava.get(`/api/event/getUpcomingEvents`).then(answer => {
      var data = answer.data;
      setPopularEvents(data);
      // console.log(data)
      setIsPageLoading(false);
    });
  };

  useEffect(() => {
    getUpcoming();
    getPopularEvents();
    // console.log('ucoming events >>>>', upcomingEvents)
  }, []);

  return (
    <Grid container style={{ marginLeft: "5%" }}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <PopularEvents popularEvents={popularEvents} />
      </Grid>

      <Grid item xs={12} md={12} lg={12} xl={12}>
        <UpcomingEvents upcomingEvents={upcomingEvents} />
      </Grid>
    </Grid>
  );
};

export default MarketEvents;
