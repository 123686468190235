import { useRouter } from "@Hooks";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import AccountTypeField from "./AccountTypeField";
import DataField from "./DataField";
import PasswordField from "./PasswordField";

const UserDetails = () => {
  const Router = useRouter();
  const [isPageLoading, setIsPageLoading] = useState(null);

  const [name, setName] = useState("default name");
  const [mail, setMail] = useState("default mail");
  const [physicalAddress, setPhysicalAddress] = useState(
    "default physicalAddress"
  );
  const [password, setPassword] = useState("****************");
  const [type, setType] = useState("default type");

  const getData = async (event) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${lscache.get("accessToken")}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      "https://katastic-node.wtzconsult.com/api/v1/user/profile",
      requestOptions
    )
      .then((response) => response.json())
      .then((answer) => {
        console.log("ANSWER:", answer);
        setName(answer.body.username);
        setMail(answer.body.email);
        setPassword(answer.body.password);
        setPhysicalAddress(answer.body.address);
        if (answer.body.isPlatinum) {
          setType("Premium");
        } else {
          setType("Basic");
        }
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error`, err.response.data)
        }
      });

    // await REST.get(`/user/profile`)
    //   .then((answer) => {
    //     setName(answer.data.body.username);
    //     setMail(answer.data.body.email);
    //     setPassword(answer.data.body.password);
    //     setPhysicalAddress(answer.data.body.address);
    //     if (answer.data.body.isPlatinum) {
    //       setType("Premium");
    //     } else {
    //       setType("Basic");
    //     }
    //     setIsPageLoading(false);
    //   })
    //   .catch((err) => {
    //     if (err && err.response && err.response.data) {
    //       const { message } = err.response.data;
    //     }
    //   });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <div className={"UserDetails"}>
        <DataField
          id={"1"}
          textData={"User Name"}
          data={name}
          setData={setName}
          defaultName={setName}
          refreshData={getData}
        />
        <DataField
          id={"2"}
          textData={"Email"}
          data={mail}
          setData={setMail}
          refreshData={getData}
        />
        <DataField
          id={"3"}
          textData={"Physical address"}
          data={physicalAddress}
          setData={setPhysicalAddress}
          refreshData={getData}
        />
        <PasswordField
          id={"1"}
          textData={"Password"}
          data={password}
          setData={setPassword}
          refreshData={getData}
        />
        <AccountTypeField
          id={"1"}
          textData={"Account Type"}
          data={type}
          setData={setType}
        />
      </div>
    );
  }
};

export default UserDetails;
