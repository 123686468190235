import React from "react";
import Grid from "@material-ui/core/Grid";
import { LoginMUIStyles } from "@Styled/Login.style";
import { ButtonPink } from "@Components";
import { useRouter } from "@Hooks";

const Step4Signup = (props) => {
  const Router = useRouter();
  const { setNextStep, isVisible, mainFormState, setMainFormState } = props;
  const LoginStyle = LoginMUIStyles();

  const redirectToProfile = () => {
    Router.push("/profile");
  };

  const handleYesInvestor = () => {
    setMainFormState({
      ...mainFormState,
      step4: {
        isInvestor: true,
      },
    });
    setNextStep(5)
  };

  const handleNoInvestor = () => {
    setMainFormState({
      ...mainFormState,
      step4: {
        isInvestor: false,
      },
    });
    setNextStep(999)
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justify="center"
      direction="column"
      className={LoginStyle.root}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <Grid item xs={12} className="mt-3-rem">
        <h1>One more thing</h1>
        <h2>Do you want to be an investor?</h2>
      </Grid>

      <Grid item xs={12}>
        <button
          name="genre"
          onClick={handleYesInvestor}
          className="buttonInactiveWithHover mr-3-rem"
        >
          Yes
        </button>

        <button
          name="genre"
          onClick={handleNoInvestor}
          className="buttonInactiveWithHover"
        >
          No
        </button>
      </Grid>
    </Grid>
  );
};

export default Step4Signup;
