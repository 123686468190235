import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import commaNumber from "comma-number";

const PortfolioTable = (props) => {

    const {
        selectedEntry,
        setSelectedEntry,
        showChart,
        onSelectForFraction,
        data,
        setSellPrice,
        setBuyPrice
    } = props;

    const [expanded, setExpanded] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleDetails = (id) => {
        setExpanded(id === selectedId ? !expanded : false)
        console.log("apelez cu : ", id)
    }

    const handleSelect = () => {
        setSellPrice(commaNumber(data.project.pricePerUnit))
        setBuyPrice(commaNumber(data.project.pricePerUnit))
        setSelectedEntry(data.project.id);
        showChart(
            data.project.id,
            data.project.valueOffer,
            data.project.numberOfUnitsSold
        );
        onSelectForFraction(data.project.valueOffer);
    };


    if (selectedEntry === data.project.id) {
        return (
            <Grid container className={`invest_table_container`} key={data.project.id}>
                <Grid item xs={12}
                    className={`invest_item`}
                    onClick={() => {
                        handleDetails(data.project.id);
                        handleSelect();
                        setSelectedId(data.project.id);
                    }}
                >
                    <Grid item xs={6} style={{ display: "flex", flexDirection: "row", paddingLeft: "1rem" }}>
                        <img src={data.project.artist.imageUrl} style={{ width: "47px", height: "47px" }} alt="" />
                        <div className={"artist"}>{data.project.artist.name}</div>
                    </Grid>

                    <Grid item xs={6}>
                        {/* <p>ARTIST</p> */}
                        {data.project.title}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}
                        className={`invest_item`}
                        style={{ justifyContent: "space-between" }}
                        onClick={() => handleSelect()}
                    >
                        <Grid item x={6}>
                            PROJECT TYPE
                        </Grid>
                        <Grid item xs={6}>
                            {data.project.category}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}
                        className={`invest_item`}
                        style={{ justifyContent: "space-between" }}
                        onClick={() => handleSelect()}
                    >
                        <Grid item x={6}>
                            CODE
                        </Grid>
                        <Grid item xs={6}>
                            {data.project.projectName}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}
                        className={`invest_item`}
                        style={{ justifyContent: "space-between" }}
                        onClick={() => handleSelect()}
                    >
                        {/* <Grid item x={6}>
                            IRO PRICE
                        </Grid> */}
                        <Grid item xs={6}>
                            $ {commaNumber(data.project.pricePerUnit)} / Right
                        </Grid>
                    </Grid>

                    <Grid item xs={12}
                        className={`invest_item`}
                        style={{ justifyContent: "space-between" }}
                        onClick={() => handleSelect()}
                    >
                        <Grid item x={6}>
                            BUY
                        </Grid>
                        <Grid item xs={6}>
                            $ {commaNumber(data.project.estimatedPriceBuy)} / Right
                        </Grid>
                    </Grid>

                    <Grid item xs={12}
                        className={`invest_item`}
                        style={{ justifyContent: "space-between" }}
                        onClick={() => handleSelect()}>
                        <Grid item x={6}>
                            SELL
                        </Grid>
                        <Grid item xs={6}>
                            $ {commaNumber(data.project.estimatedPriceSell)} / Right
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    } else {
        return (
            <Grid container className={`invest_table_container`} key={data.project.id}>
                {/* <Grid item xs={6} className={`table_head`}>
                Artist
            </Grid>
            <Grid item xs={6} className={`table_head`}>
                Project Name
            </Grid> */}

                <Grid item xs={12}
                    className={`invest_item`}
                    onClick={() => {
                        handleDetails(data.project.id);
                        handleSelect();
                        setSelectedId(data.project.id);
                    }}
                >
                    <Grid item xs={6} style={{ display: "flex", flexDirection: "row", paddingLeft: "1rem" }}>
                        <img src={data.project.artist.imageUrl} style={{ width: "47px", height: "47px" }} alt="" />
                        <div className={"artist"}>{data.project.artist.name}</div>
                    </Grid>

                    <Grid item xs={6}>
                        {/* <p>ARTIST</p> */}
                        {data.project.title}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default PortfolioTable;