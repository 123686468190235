import downArrow from '@Images/down-arrow.png';
import upArrow from '@Images/up-arrow.png';
import Grid from "@material-ui/core/Grid";
import commaNumber from "comma-number";
import React from "react";

const InvestPortofolioRightsEntry = (props) => {
  const {
    id,
    forActive,
    item,
    valueCurrency,
    growth,
    showDueDate,
    setSelectedEntry,
    selectedEntry,
    showChart,
    handleProjectId
  } = props;


  const handleSelect = () => {
    setSelectedEntry(id);
    showChart(id);
    if (window.location.pathname === "/portofolio-rights") {
      handleProjectId(id)
    }
  };

  console.log("MY ITEM >>", item)

  if (selectedEntry === id) {
    return (
      <Grid
        container
        className={"selectedInvestPortofolioRightsEntry"}
        onClick={() => handleSelect()}
      >
        <Grid item xs={2} style={{ textAlign: "start" }}>
          <img src={item && item.artist.imageUrl} style={{ width: "47px", height: "47px" }} alt="" />
          {item && item.artist.name}
        </Grid>
        <Grid item xs={2}>
          {item && item.name}
        </Grid>
        <Grid item xs={forActive ? 1 : 2} style={{ textAlign: "center" }} className={"rights"}>
          {item && item.projectType}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"rights"}>
          {/* {rights} */}
          {commaNumber(item && item.numberOfUnitsBuyed)}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"value"}>
          $ {commaNumber(item && item.pricePerUnit)}
        </Grid>
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            {commaNumber(item && item.numberOfUnitsBuyed)}
          </Grid>
        ) : null}
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            $ {commaNumber(item && item.pricePerUnit)}
          </Grid>
        ) : null}
        {window.location.pathname === "/portofolio-rights" ?
          <Grid item xs={2} className={`variation`} >
            <div>
              {item.sensOfVariation ? "+" : "-"}${item.variation}
            </div>
            <div>
              {item && item.sensOfVariation ?
                <img className={"arrow"} src={upArrow} alt="" style={{ height: 13, width: 10, marginLeft: "0.5rem" }} />
                :
                <img className={"arrow"} src={downArrow} alt="" style={{ height: 13, width: 10, marginLeft: "0.5rem" }} />
              }
              {item.sensOfVariation ? null : "-"}{item.variationPercentage}%
            </div>
          </Grid> : null
        }
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        className={"investPortofolioRightsEntry"}
        onClick={() => handleSelect()}
      >
        <Grid item xs={2} style={{ textAlign: "start" }}>
          <img src={item && item.artist.imageUrl} style={{ width: "47px", height: "47px" }} alt="" />
          {item && item.artist.name}
        </Grid>
        <Grid item xs={2} >
          {item && item.name}
        </Grid>
        <Grid item xs={forActive ? 1 : 2} style={{ textAlign: "center" }} className={"rights"}>
          {item && item.projectType}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"rights"}>
          {commaNumber(item && item.numberOfUnitsBuyed)}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"value"}>
          $ {commaNumber(item && item.pricePerUnit)}
        </Grid>
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            {commaNumber(item && item.numberOfUnitsBuyed)}
          </Grid>
        ) : null}
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            $ {commaNumber(item && item.pricePerUnit)}
          </Grid>
        ) : null}
        {window.location.pathname === "/portofolio-rights" ?
          <Grid item xs={2} className={`variation`} >
            <div>
              {item.sensOfVariation ? "+" : "-"}${item.variation}
            </div>
            <div>
              {item && item.sensOfVariation ?
                <img className={"arrow"} src={upArrow} alt="" style={{ height: 13, width: 10, marginLeft: "0.5rem" }} />
                :
                <img className={"arrow"} src={downArrow} alt="" style={{ height: 13, width: 10, marginLeft: "0.5rem" }} />
              }
              {item.sensOfVariation ? null : "-"}{item.variationPercentage}%
            </div>
          </Grid> : null
        }
      </Grid>
    );
  }
};

export default InvestPortofolioRightsEntry;
