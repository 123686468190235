export const mock1 = [
    {
        "id": 2,
        "creationDate": "2021-04-15T13:05:03.000+0000",
        "lastUpdate": "2021-04-15T13:05:03.000+0000",
        "numberOfUnitsSold": 100,
        "pricePerUnit": 11.0,
        "user": {
            "id": 101,
            "creationDate": "2021-04-14T07:10:45.000+0000",
            "lastUpdate": "2021-04-14T07:10:45.000+0000",
            "username": "chisu",
            "email": "v64du1@gmail.com",
            "password": "J1yuuG5YgtsNoo6t/Dp3qrThHn/IMsNoGIJeyaxhHv4=",
            "birthdate": "1997-06-11",
            "imageUrl": null,
            "isverified": false,
            "nodeId": "6084829a-e342-4db5-94de-d9a00e6f8b0d",
            "valid": false,
            "proIndividual": false,
            "proEntity": false,
            "platinum": false
        },
        "project": {
            "id": 8,
            "creationDate": "2021-04-14T10:11:06.000+0000",
            "lastUpdate": "2021-04-14T10:11:06.000+0000",
            "user": {
                "id": 101,
                "creationDate": "2021-04-14T07:10:45.000+0000",
                "lastUpdate": "2021-04-14T07:10:45.000+0000",
                "username": "chisu",
                "email": "v64du1@gmail.com",
                "password": "J1yuuG5YgtsNoo6t/Dp3qrThHn/IMsNoGIJeyaxhHv4=",
                "birthdate": "1997-06-11",
                "imageUrl": null,
                "isverified": false,
                "nodeId": "6084829a-e342-4db5-94de-d9a00e6f8b0d",
                "valid": false,
                "proIndividual": false,
                "proEntity": false,
                "platinum": false
            },
            "numberOfUnitsBuyed": 9900,
            "project": {
                "id": 5,
                "creationDate": "2021-04-14T09:51:17.000+0000",
                "lastUpdate": "2021-04-14T09:51:17.000+0000",
                "releaseDate": "2021-04-13T00:00:00.000+0000",
                "dueDate": "2021-04-30T00:00:00.000+0000",
                "projectName": "AEA",
                "genre": "Electronic music",
                "percentage": 20.0,
                "tenor": 5.0,
                "tenorSufix": "Years",
                "estimtedYield": 7.0,
                "estimatedYieldDescription": "According to past perfomance",
                "valueOffer": 200000.0,
                "numberOfUnitsSold": 20000,
                "numberOfRightsSold": 20000,
                "installments": "quarterly",
                "category": "Single",
                "location": "",
                "start": null,
                "end": null,
                "valid": true,
                "link": "",
                "currency": "USD",
                "info": "Orice",
                "pricePerUnit": 10.0,
                "estimatedPriceSell": 10.0,
                "estimatedPriceBuy": 10.0,
                "draft": false,
                "estimatedValue": 1000000.0,
                "projectType": "OTC",
                "title": "Downtown",
                "underwriter": null,
                "artist": {
                    "id": 14,
                    "nodeId": null,
                    "name": "Studio",
                    "imageUrl": "https://q-xx.bstatic.com/images/hotel/max500/227/227567628.jpg",
                    "userRating": 9.5,
                    "proRating": 9.0,
                    "followerCount": 90000,
                    "about": "Steven Hiroyuki Aoki is an American DJ, record producer, music programmer, and record executive. In 2012, Pollstar designated Aoki as the highest grossing dance artist in North America from tours.",
                    "email": "andrei.patza@wtzconsult.com",
                    "createdAt": "2021-04-12T13:11:01.000+0000",
                    "updatedAt": "2021-04-12T13:11:01.000+0000"
                },
                "endorsedBy": {
                    "id": 7,
                    "nodeId": "120b37b4-34a1-467c-9c9d-00836be36717",
                    "name": "Zella Bashirian III",
                    "imageUrl": "http://lorempixel.com/640/480",
                    "userRating": 3.0,
                    "proRating": 3.0,
                    "followerCount": 63234,
                    "about": null,
                    "email": "andrei.patzaa@wtzconsult.com",
                    "createdAt": "2020-11-08T12:34:57.000+0000",
                    "updatedAt": "2020-11-08T12:34:57.000+0000"
                },
                "ipro": true
            },
            "pricePerRight": 10.0
        },
        "initialProject": null,
        "valid": true
    },
    {
        "id": 4,
        "creationDate": "2021-04-15T12:45:07.000+0000",
        "lastUpdate": "2021-04-15T12:45:07.000+0000",
        "numberOfUnitsSold": 9,
        "pricePerUnit": 11.0,
        "user": {
            "id": 101,
            "creationDate": "2021-04-14T07:10:45.000+0000",
            "lastUpdate": "2021-04-14T07:10:45.000+0000",
            "username": "chisu",
            "email": "v64du1@gmail.com",
            "password": "J1yuuG5YgtsNoo6t/Dp3qrThHn/IMsNoGIJeyaxhHv4=",
            "birthdate": "1997-06-11",
            "imageUrl": null,
            "isverified": false,
            "nodeId": "6084829a-e342-4db5-94de-d9a00e6f8b0d",
            "valid": false,
            "proIndividual": false,
            "proEntity": false,
            "platinum": false
        },
        "project": {
            "id": 112,
            "creationDate": "2021-04-14T13:09:50.000+0000",
            "lastUpdate": "2021-04-14T13:09:50.000+0000",
            "user": {
                "id": 101,
                "creationDate": "2021-04-14T07:10:45.000+0000",
                "lastUpdate": "2021-04-14T07:10:45.000+0000",
                "username": "chisu",
                "email": "v64du1@gmail.com",
                "password": "J1yuuG5YgtsNoo6t/Dp3qrThHn/IMsNoGIJeyaxhHv4=",
                "birthdate": "1997-06-11",
                "imageUrl": null,
                "isverified": false,
                "nodeId": "6084829a-e342-4db5-94de-d9a00e6f8b0d",
                "valid": false,
                "proIndividual": false,
                "proEntity": false,
                "platinum": false
            },
            "numberOfUnitsBuyed": 9990,
            "project": {
                "id": 6,
                "creationDate": "2021-04-14T12:47:25.000+0000",
                "lastUpdate": "2021-04-14T12:47:25.000+0000",
                "releaseDate": "2021-04-13T00:00:00.000+0000",
                "dueDate": "2021-04-30T00:00:00.000+0000",
                "projectName": "YFL",
                "genre": "Electronic music",
                "percentage": 20.0,
                "tenor": 5.0,
                "tenorSufix": "years",
                "estimtedYield": 7.0,
                "estimatedYieldDescription": "yearly",
                "valueOffer": 200000.0,
                "numberOfUnitsSold": 20000,
                "numberOfRightsSold": 20000,
                "installments": "quarterly",
                "category": "Single",
                "location": "",
                "start": null,
                "end": null,
                "valid": true,
                "link": "",
                "currency": "USD",
                "info": "Some info",
                "pricePerUnit": 10.0,
                "estimatedPriceSell": 10.0,
                "estimatedPriceBuy": 10.0,
                "draft": false,
                "estimatedValue": 1000000.0,
                "projectType": "OTC",
                "title": "Luxury residential",
                "underwriter": null,
                "artist": {
                    "id": 14,
                    "nodeId": null,
                    "name": "Penthouse",
                    "imageUrl": "https://img.gta5-mods.com/q95/images/mlo-designer-penthouse-remake-add-on-sp/65e8c6-15.jpg",
                    "userRating": 9.5,
                    "proRating": 9.0,
                    "followerCount": 90000,
                    "about": "Steven Hiroyuki Aoki is an American DJ, record producer, music programmer, and record executive. In 2012, Pollstar designated Aoki as the highest grossing dance artist in North America from tours.",
                    "email": "andrei.patza@wtzconsult.com",
                    "createdAt": "2021-04-12T13:11:01.000+0000",
                    "updatedAt": "2021-04-12T13:11:01.000+0000"
                },
                "endorsedBy": {
                    "id": 7,
                    "nodeId": "120b37b4-34a1-467c-9c9d-00836be36717",
                    "name": "Zella Bashirian III",
                    "imageUrl": "http://lorempixel.com/640/480",
                    "userRating": 3.0,
                    "proRating": 3.0,
                    "followerCount": 63234,
                    "about": null,
                    "email": "andrei.patzaa@wtzconsult.com",
                    "createdAt": "2020-11-08T12:34:57.000+0000",
                    "updatedAt": "2020-11-08T12:34:57.000+0000"
                },
                "ipro": true
            },
            "pricePerRight": 10.0
        },
        "initialProject": null,
        "valid": true
    }
]

export const mock2 = [
    {
        "id": 10,
        "creationDate": "2021-04-15T12:03:13.000+0000",
        "lastUpdate": "2021-04-15T12:03:13.000+0000",
        "numberOfUnitsBuyed": 2,
        "project": {
            "id": 10,
            "creationDate": "2021-04-15T11:35:47.000+0000",
            "lastUpdate": "2021-04-15T11:35:47.000+0000",
            "releaseDate": "2021-04-01T00:00:00.000+0000",
            "dueDate": "2021-04-30T00:00:00.000+0000",
            "projectName": "ABC",
            "genre": "Country",
            "percentage": 12.0,
            "tenor": 12.0,
            "tenorSufix": "12",
            "estimtedYield": 12.0,
            "estimatedYieldDescription": "12",
            "valueOffer": 12.0,
            "numberOfUnitsSold": 12,
            "numberOfRightsSold": 2,
            "installments": "one off (bullet)",
            "category": "Tour",
            "location": "Bucharest",
            "start": "2021-04-01T00:00:00.000+0000",
            "end": "2021-04-30T00:00:00.000+0000",
            "valid": true,
            "link": "",
            "currency": "USD",
            "info": null,
            "pricePerUnit": 12.0,
            "estimatedPriceSell": 12.0,
            "estimatedPriceBuy": 12.0,
            "draft": false,
            "estimatedValue": 1.0,
            "projectType": "OTC",
            "title": "Downtown real estate",
            "underwriter": null,
            "artist": {
                "id": 14,
                "nodeId": null,
                "name": "Big House",
                "imageUrl": "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-2000w,f_auto,q_auto:best/newscms/2019_24/1448814/how-size-doesnt-make-you-happier-today-main-190614.jpg",
                "userRating": 9.5,
                "proRating": 9.0,
                "followerCount": 90000,
                "about": "Steven Hiroyuki Aoki is an American DJ, record producer, music programmer, and record executive. In 2012, Pollstar designated Aoki as the highest grossing dance artist in North America from tours.",
                "email": "andrei.patza@wtzconsult.com",
                "createdAt": "2021-04-12T13:11:01.000+0000",
                "updatedAt": "2021-04-12T13:11:01.000+0000"
            },
            "ipro": false
        },
        "pricePerRight": 12.0
    },
    {
        "id": 18,
        "creationDate": "2021-04-15T12:03:13.000+0000",
        "lastUpdate": "2021-04-15T12:03:13.000+0000",
        "numberOfUnitsBuyed": 2,
        "project": {
            "id": 11,
            "creationDate": "2021-04-15T11:35:47.000+0000",
            "lastUpdate": "2021-04-15T11:35:47.000+0000",
            "releaseDate": "2021-04-01T00:00:00.000+0000",
            "dueDate": "2021-04-30T00:00:00.000+0000",
            "projectName": "MRR",
            "genre": "Country",
            "percentage": 12.0,
            "tenor": 12.0,
            "tenorSufix": "12",
            "estimtedYield": 12.0,
            "estimatedYieldDescription": "12",
            "valueOffer": 12.0,
            "numberOfUnitsSold": 12,
            "numberOfRightsSold": 2,
            "installments": "one off (bullet)",
            "category": "Tour",
            "location": "Bucharest",
            "start": "2021-04-01T00:00:00.000+0000",
            "end": "2021-04-30T00:00:00.000+0000",
            "valid": true,
            "link": "",
            "currency": "USD",
            "info": null,
            "pricePerUnit": 12.0,
            "estimatedPriceSell": 15.0,
            "estimatedPriceBuy": 102.0,
            "draft": false,
            "estimatedValue": 1.0,
            "projectType": "OTC",
            "title": "Industrial zone",
            "underwriter": null,
            "artist": {
                "id": 14,
                "nodeId": null,
                "name": "Warehouse",
                "imageUrl": "https://img.etimg.com/thumb/msid-84086356,width-300,imgsize-440917,,resizemode-4,quality-100/istock-1138429558.jpg",
                "userRating": 9.5,
                "proRating": 9.0,
                "followerCount": 90000,
                "about": "Steven Hiroyuki Aoki is an American DJ, record producer, music programmer, and record executive. In 2012, Pollstar designated Aoki as the highest grossing dance artist in North America from tours.",
                "email": "andrei.patza@wtzconsult.com",
                "createdAt": "2021-04-12T13:11:01.000+0000",
                "updatedAt": "2021-04-12T13:11:01.000+0000"
            },
            "ipro": false
        },
        "pricePerRight": 12.0
    }

]