import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { RouterPush } from '@Utils';
import MediaForYou from '../mediaForYou';
const Media = () => {
  return (
    // <Grid container className="Media">
    //   <Grid item xs={12} className="titleContainer">
    //     <p className="titleMedia">MEDIA</p>
    //   </Grid>
    //   <Grid item xs={12} className="subTitleContainer">
    //     <p className="subTitleMedia">News</p>
    //   </Grid>

    //   <Grid container className="categoriesContainer">
    //     <div className="forYouCategory" onClick={RouterPush('/media')}>
    //       <p className="forYouText">FOR YOU</p>
    //     </div>
    //     <div
    //       className="editorsPickCategory"
    //       onClick={RouterPush('/mediaEditorsPick')}
    //     >
    //       <p className="editorsPickText">EDITOR'S PICK</p>
    //     </div>
    //     <div
    //       className="topStoriesCategory"
    //       onClick={RouterPush('/mediaTopStories')}
    //     >
    //       <p className="topStoriesText">TOP STORIES</p>
    //     </div>
    //   </Grid>
    // </Grid>
    <MediaForYou />
  );
};

export default Media;
