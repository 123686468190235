import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import UAParser from "ua-parser-js";
// import { useRouter } from "@Hooks";

import { theme } from "@Styled/Theme";
import "./Assets/scss/index.scss";
import validators from "./Common/validators";
import Routes from "./Routes";
import lscache from "lscache";

const browserHistory = createBrowserHistory();
const parser = new UAParser();

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mediaID, setMediaID] = useState(lscache.get("mediaID"));
  const [updateWallet, setUpdateWallet] = useState(null);
  const [isAuthed, setIsAuthed] = useState(null);
  const [toPlay, setToPlay] = useState({
    list: [],
    playSongIndex: null
  });

  // const triggerWalletUpdate = value => {
  //   setUpdateWallet(value);
  // };

  return (
      <ThemeProvider theme={theme}>
        <Router
          history={browserHistory}
          // triggerWalletUpdate={triggerWalletUpdate}
        >
          <Routes parser={parser} isAuthenticated={isAuthed} />
          {/* <Routes parser={parser} /> */}
        </Router>
      </ThemeProvider>
  );
};

export default App;
