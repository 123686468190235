import React, { useState, useEffect } from "react";

import DataField from "./DataField";
import DropdownField from "./DropdownField";
import LinkField from "./LinkField";
import NotificationField from "./NotificationField";
import { REST, rURL } from "@Utils";
import lscache from "lscache";

const SettingsPanel = () => {
  const [isPageLoading, setIsPageLoading] = useState(null);

  const [type, setType] = useState("default");
  const [notifications, setNotifications] = useState("default");

  const getData = async (event) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${lscache.get("accessToken")}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      "https://katastic-node.wtzconsult.com/api/v1/user/profile",
      requestOptions
    )
      .then((response) => response.json())
      .then((answer) => {
        if (answer.body.allowNotifications) {
          setNotifications("Allowed");
        }
        if (!answer.body.allowNotifications) {
          setNotifications("Unallowed");
        }

        switch (answer.body.streamingQuality) {
          case 0:
            setType("Low");
            break;
          case 1:
            setType("Medium");
            break;
          case 2:
            setType("High");
            break;
          default:
            break;
        }
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });

    // await REST.get(`/user/profile`)
    //   .then((answer) => {
    //     console.log("BOOOOL :  ", answer);
    // if (answer.data.body.allowNotifications) {
    //   setNotifications("Allowed");
    // }
    // if (!answer.data.body.allowNotifications) {
    //   setNotifications("Unallowed");
    // }

    // switch (answer.data.body.streamingQuality) {
    //   case 0:
    //     setType("Low");
    //     break;
    //   case 1:
    //     setType("Medium");
    //     break;
    //   case 2:
    //     setType("High");
    //     break;
    //   default:
    //     break;
    // }
    // setIsPageLoading(false);
    //   })
    //   .catch((err) => {
    //     if (err && err.response && err.response.data) {
    //       const { message } = err.response.data;
    //     }
    //   });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <div className={"SettingsPanel"}>
        <p className="SettingsHeader">SETTINGS</p>
        <NotificationField
          id={1}
          textData={"Notifications"}
          data={notifications}
          setData={setNotifications}
          refreshData={getData}
        />
        <DropdownField
          id={2}
          textData={"Streaming Quality"}
          data={type}
          setData={setType}
          refreshData={getData}
        />
        <LinkField id={1} textData={"Advertising"} />
        {/* <LinkField id={2} textData={"Terms and Conditions"} /> */}
        {/* <LinkField id={3} textData={"Privacy Policy"} /> */}
        <LinkField id={4} textData={"Support"} />
      </div>
    );
  }
};

export default SettingsPanel;
