import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from 'react-player';

const VideoPlayer = () => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleMuted = () => {
    setMuted(!muted);
  };

  const videoUrl = '../../../../public/static/video/video.mp4';

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={'overlay'}>
          <ReactPlayer
            // url='rtmp://catalin.wtzconsult.com/live'
            url="https://www.youtube.com/watch?v=3CxtK7-XtE0&list=RD3CxtK7-XtE0&start_radio=1&ab_channel=DJKhaledVEVO"
            // url={videoUrl}
            // controls={true}
            type="video/mp4"
            volume={1}
            width={'100%'}
            height={600}
            playing={playing}
            muted={muted}
            playsinline={true}
            pip={true}
            stopOnUnmount={false}
          />
          <div className={'ovelay-text'}>
            <h4 className={'item'}>PREMIUM CONTENT</h4>
            <h4 className={'item'}>FREE WITH A PLATINUM ACCOUNT</h4>
            <h6 className={'item'}>OR</h6>
            <h6 className={'item'}>PLAY A ONE TIME FEE OF 5JT TO WATCH</h6>
          </div>
        </div>
      </Grid>
      <button onClick={handlePlayPause}>Play / Pause</button>
      <button onClick={handleMuted}>Muted / Unmuted</button>
    </Grid>
  );
};

export default VideoPlayer;
