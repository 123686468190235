import React, { useEffect, useState } from "react";

import { TextField, Grid, Select, MenuItem, Input } from "@material-ui/core";
import firstStepSchema from "../schemas/firstStepSchema";
import validate from "validate.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { REST, RESTjava } from "@Utils";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getAllByRole } from "@testing-library/dom";

export const FirstStep = (props) => {
  const {
    currentStep,
    setCurrentStep,
    handleChange,
    formState,
    setFormState,
    getAllArtists,
  } = props;

  const [openGenre, setOpenGenre] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);
  const [showLocation, setShowLocation] = React.useState(false);

  const [genrelist, setGenreList] = useState([]);

  const [getAllGenre, setGetAllGenre] = useState([{ id: "1", genre: "YLM - Studio - Downtown" }, { id: "2", genre: "AEA - Warehouse - Industrial zone" }, { id: "3", genre: "TUS - Penthouse - Luxury residential" }, { id: "4", genre: "VFR - Big House - Downtown real estate" }]);
  const [getAllCategory, setGetAllCategory] = useState(["Studio", "Warehouse"]);
  const [getAllLocations, setGetAllLocations] = useState([]);
  const [artistId, setArtistId] = useState(null);
  const [endorsedById, setEndorsedById] = useState(null);
  const [error, setError] = useState({ message: null });

  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [selectedReleaseDate, setSelectedReleaseDate] = useState(new Date());
  const [selectedDueDate, setSelectedDueDate] = useState(new Date());

  const [selectedFromDateName, setSelectedFromDateName] = useState(null);

  const screenWidth = window.innerWidth;

  const getAllGenreRequest = async () => {
    await RESTjava.get(`/api/genre/getAllGenre`)
      .then((answer) => {
        setError({ message: null });

        setGetAllGenre(answer.data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };
  const getAllCategoryRequest = async () => {
    await RESTjava.get(`/api/category/getAllCategory`)
      .then((answer) => {
        setError({ message: null });

        setGetAllCategory(answer.data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };
  const getAllLocationsRequest = async () => {
    await RESTjava.get(`/api/location/getAllLocations`)
      .then((answer) => {
        setError({ message: null });

        setGetAllLocations(answer.data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  useEffect(() => {
    const errors = validate(formState.values, firstStepSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (artistId !== null) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          artistId: artistId,
        },
      }));
    }
  }, [formState.values["artistOrBand"]]);

  useEffect(() => {
    if (endorsedById !== null) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          endorsedById: endorsedById,
        },
      }));
    }
  }, [formState.values["endorsedBy"]]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    if (formState.values["categorySelect"] === "Tour") {
      setShowLocation(true);
    } else {
      setShowLocation(false);
    }
  }, [formState.values["categorySelect"]]);

  useEffect(() => {
    if (formState.values["genreSelect"]) {
      const tempList = [...genrelist];
      tempList.push(formState.values["genreSelect"]);
      setGenreList(tempList);
    }
  }, [formState.values["genreSelect"]]);

  const handleRemoveTag = (item, index) => {
    if (index > -1) {
      const newGenreList = [...genrelist];
      newGenreList.splice(index, 1);
      setGenreList(newGenreList);
    }
  };

  // useEffect(() => {
  //   getAllGenreRequest();
  //   getAllCategoryRequest();
  //   getAllLocationsRequest();
  // }, []);

  const handleDateChange = (newValue, name) => {
    if (name === "fromDate") {
      setSelectedFromDate(newValue);
      setSelectedFromDateName(name);
    } else if (name === "toDate") {
      setSelectedFromDateName(name);
      setSelectedToDate(newValue);
    } else if (name === "releaseDate") {
      setSelectedFromDateName(name);
      setSelectedReleaseDate(newValue);
    } else if (name === "dueDate") {
      setSelectedFromDateName(name);
      setSelectedDueDate(newValue);
    }
  };

  useEffect(() => {
    if (selectedFromDateName) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [selectedFromDateName]: selectedFromDate,
        },
        touched: {
          ...formState.touched,
          [selectedFromDateName]: true,
        },
      }));
    }
  }, [selectedFromDate]);
  useEffect(() => {
    if (selectedFromDateName) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [selectedFromDateName]: selectedToDate,
        },
        touched: {
          ...formState.touched,
          [selectedFromDateName]: true,
        },
      }));
    }
  }, [selectedToDate]);

  useEffect(() => {
    if (selectedFromDateName) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [selectedFromDateName]: selectedReleaseDate,
        },
        touched: {
          ...formState.touched,
          [selectedFromDateName]: true,
        },
      }));
    }
  }, [selectedReleaseDate]);

  useEffect(() => {
    if (selectedFromDateName) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [selectedFromDateName]: selectedDueDate,
        },
        touched: {
          ...formState.touched,
          [selectedFromDateName]: true,
        },
      }));
    }
  }, [selectedDueDate]);

  console.log("all artists : > ", getAllArtists)

  return (
    <Grid container className="firstPageContainer">
      <Grid item xs={12} className="titleContainer">
        <p className="titleText">Create Project</p>
      </Grid>
      <Grid item xs={12} className="stepTrackerContainer">
        <div className="firstStepTracker">1</div>
        <div className="firstStepTrackerLine" />
        <div className="secondStepTracker">2</div>
        <div className="secondStepTrackerLine" />
        <div className="thirdStepTracker">3</div>
      </Grid>

      <Grid item xs={12}>
        <p className="contentTitle">Complete the following fields: </p>
      </Grid>
      <div className="allContentContainer">
        <Grid item xs={12} className="contentContainer">
          <Grid item xs={12} className="artistBandContainer">
            <p className="artistBandTitle" style={{ marginBottom: "10px" }}>
              Project Title
            </p>
            <TextField
              className="infoInput"
              id="projectTitle"
              name="projectTitle"
              color="secondary"
              placeholder="Project Title"
              onChange={handleChange}
              value={formState.values["projectTitle"]}
              error={hasError("projectTitle")}
              helperText={
                hasError("projectTitle")
                  ? formState.errors.projectTitle[0]
                  : null
              }
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="artistBandContainer">
            <p className="artistBandTitle">Artist/Band</p>
            <Autocomplete
              options={getAllArtists}
              getOptionLabel={(option) =>
                option !== undefined
                  ? option.name
                    ? option.name
                    : option
                  : null
              }
              getOptionSelected={(option) => setArtistId(option.id)}
              defaultValue={
                formState.values["artistOrBand"]
                  ? formState.values["artistOrBand"]
                  : ""
              }
              placeholder="Search"
              onSelect={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="artistBandTextField"
                  id="artistOrBand"
                  name="artistOrBand"
                  color="secondary"
                  variant="filled"
                  placeholder="e.g. John Doe"
                  onChange={handleChange}
                  value={formState.values["artistOrBand"]}
                  error={hasError("artistOrBand")}
                  helperText={
                    hasError("artistOrBand")
                      ? formState.errors.artistOrBand[0]
                      : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} className="endorsedByContainer">
            <p className="endorsedByTitle">Endorsed by</p>
            <Autocomplete
              options={getAllArtists}
              getOptionLabel={(option) =>
                option !== undefined
                  ? option.name
                    ? option.name
                    : option
                  : null
              }
              defaultValue={
                formState.values["endorsedBy"]
                  ? formState.values["endorsedBy"]
                  : ""
              }
              onSelect={handleChange}
              placeholder="Search"
              getOptionSelected={(option) => setEndorsedById(option.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="artistBandTextField"
                  id="endorsedBy"
                  name="endorsedBy"
                  color="secondary"
                  variant="filled"
                  placeholder="e.g. John Doe"
                  onChange={handleChange}
                  value={formState.values["endorsedBy"]}
                  error={hasError("endorsedBy")}
                  helperText={
                    hasError("endorsedBy")
                      ? formState.errors.endorsedBy[0]
                      : null
                  }
                />
              )}
            />
          </Grid>
        </Grid> */}
        <Grid item xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="endorsedByContainer">
            <p className="endorsedByTitle">IRO START Date</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="fromDate"
                name="fromDate"
                placeholder={"ZZ/LL/AAAA"}
                format="dd/MM/yyyy"
                value={selectedReleaseDate}
                onChange={(newValue) =>
                  handleDateChange(newValue, "releaseDate")
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6} className="endorsedByContainer">
            <p className="endorsedByTitle">IRO END Date</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="fromDate"
                name="fromDate"
                placeholder={"ZZ/LL/AAAA"}
                format="dd/MM/yyyy"
                value={selectedDueDate}
                onChange={(newValue) => handleDateChange(newValue, "dueDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} className="contentContainer">
          <Grid item container direction={screenWidth > 500 ? 'row' : 'column'} xs={12} md={6} className="genreAndCategoryContainer">
            <Grid item container xs={12} className="genreContainer">
              <Grid item xs={12} md={6} className="genreDropdownContainer">
                <p className="genreDropdownTitle">Assets</p>
                <Select
                  labelId="genreSelectLabel"
                  id="genreSelect"
                  name="genreSelect"
                  placeholder="Select"
                  open={openGenre}
                  onClose={() => setOpenGenre(false)}
                  onOpen={() => setOpenGenre(true)}
                  onChange={handleChange}
                  value={formState.values["genreSelect"]}
                  className="genreSelect"
                  error={hasError("genreSelect")}
                  helperText={
                    hasError("genreSelect")
                      ? formState.errors.genreSelect[0]
                      : null
                  }
                >
                  {getAllGenre.map((item) => (

                    <MenuItem key={item.id} value={item.genre}>
                      {item.genre}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} className="genreTagsContainer">
                {genrelist.map((item, index) => (
                  <div
                    className="genreTagsWrapper"
                    onClick={() => handleRemoveTag(item, index)}
                  >
                    <p className="genreTagsText">{item}</p>
                  </div>
                ))}
              </Grid>
            </Grid>
            {/* <Grid item xs={12} className="categoryContainer">
              <p className="categoryDropdownTitle">Category</p>
              <Select
                labelId="categorySelectLabel"
                id="categorySelect"
                name="categorySelect"
                open={openCategory}
                onClose={() => setOpenCategory(false)}
                onOpen={() => setOpenCategory(true)}
                onChange={handleChange}
                value={formState.values["categorySelect"]}
                className="categorySelect"
                error={hasError("categorySelect")}
                helperText={
                  hasError("categorySelect")
                    ? formState.errors.categorySelect[0]
                    : null
                }
              >
                {getAllCategory.map((item) => (
                  <MenuItem key={item.id} value={item.category}>
                    {item.category}
                  </MenuItem>
                ))}
              </Select>
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={6} className="infoContainer">
            <Grid item xs={12} className="infoInputContainer">
              <p className="artistBandTitle" style={{ color: "black" }}>Info</p>
              <TextField
                className="infoInput"
                id="info"
                name="info"
                color="secondary"
                multiline
                rows={10}
                placeholder="Info"
                onChange={handleChange}
                value={formState.values["info"]}
                error={hasError("info")}
                helperText={hasError("info") ? formState.errors.info[0] : null}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="contentContainer">
          {showLocation ? (
            <Grid item xs={12} md={6} className="locationContainer">
              <Grid item xs={12} md={6} className="locationInputContainer">
                <p className="locationDropdownTitle">Location</p>
                <Select
                  labelId="locationSelectLabel"
                  id="locationSelect"
                  name="locationSelect"
                  open={openLocation}
                  onClose={() => setOpenLocation(false)}
                  onOpen={() => setOpenLocation(true)}
                  onChange={handleChange}
                  value={formState.values["locationSelect"]}
                  error={hasError("locationSelect")}
                  helperText={
                    hasError("locationSelect")
                      ? formState.errors.locationSelect[0]
                      : null
                  }
                >
                  {getAllLocations.map((item) => (
                    <MenuItem key={item.id} value={item.location}>
                      {item.location}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} className="dateContainer">
                <Grid item xs={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fromDate"
                      name="fromDate"
                      placeholder={"ZZ/LL/AAAA"}
                      format="dd/MM/yyyy"
                      value={selectedFromDate}
                      onChange={(newValue) =>
                        handleDateChange(newValue, "fromDate")
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {screenWidth > 500 ?
                  <Grid item xs={2}>
                    <div className="lineDate" />
                  </Grid>
                  :
                  null
                }

                <Grid item xs={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fromDate"
                      name="fromDate"
                      placeholder={"ZZ/LL/AAAA"}
                      format="dd/MM/yyyy"
                      value={selectedToDate}
                      onChange={(newValue) =>
                        handleDateChange(newValue, "toDate")
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>


        <Grid
          xs={12}
          className={
            formState.isValid ? "buttonContainer" : "buttonContainerDisabled"
          }
        >
          <div
            onClick={
              formState.isValid ? () => setCurrentStep("secondStep") : null
            }
            className="buttonTextContainer"
          >
            <p className="buttonText">Next step</p>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default FirstStep;
