import React from 'react';
import { unkownArtist } from '@Helpers/ImageRoutes/MusicPlayer';

const CarouselListItemSecondary = props => {
  const { item, navigateOn } = props;

  const artistImgDisplay = () => {
    return item &&
      item.servicePhotoPath &&
      item.servicePhotoPath.includes('http')
      ? item.servicePhotoPath
      : unkownArtist;
  };

  return (
    <div
      className={'ListItem'}
      style={{ borderRadius: '15px' }}
      onClick={() => navigateOn(item.id)}
    >
      <div>
        <img
          src={artistImgDisplay()}
          style={{
            height: '150px',
            width: '150px',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
        />
      </div>
      <div>{item.title}</div>
    </div>
  );
};

export default CarouselListItemSecondary;
