import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MarketFilters from "../market/Components/Filters/MarketFilters";

import Services from "./Components/Services/Services";
import { marketItemList } from "@Utils/mocks/marketItemsList";
import MerchList from "./Components/MerchList/MerchList";
import Banner from "./Components/Banner/Banner";
import { RESTjava } from "@Utils";
import { useRouter } from "@Hooks";

export const MarketServices = () => {
  const Router = useRouter();
  const [marketItems, setMarketItems] = useState(marketItemList);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getServices = async () => {
    await RESTjava.get(
      `/api/market/service/getServiceByCategory?category=PRODUCER&page=0&size=100`
    )
      .then(answer => {
        var data = answer.data.content;
        setServices(data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={"services-root"}>
        {/* <Grid item xs={12} md={12} lg={12} xl={12}>
        <MarketFilters />
      </Grid> */}

        <Grid item xs={12} md={12} lg={12} xl={12} className={"services-body"}>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <Services />
          </Grid>

          <Grid item xs={6} md={6} lg={6} xl={6}>
            <Banner />
          </Grid>
        </Grid>

        <div style={{ fontSize: "20px", fontWeight: "bold" }}>Producers</div>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={"bottom-container"}
        >
          <MerchList services={services} />
        </Grid>
      </Grid>
    );
  }
};

export default MarketServices;
