import Grid from "@material-ui/core/Grid";
import commaNumber from "comma-number";
import React from "react";
const TradeEntry = (props) => {
  const {
    selectedEntry,
    setSelectedEntry,
    showChart,
    onSelectForFraction,
    data,
    setSellPrice,
    setBuyPrice
  } = props;

  const handleSelect = () => {
    setSellPrice(commaNumber(data.project.pricePerUnit))
    setBuyPrice(commaNumber(data.project.pricePerUnit))
    setSelectedEntry(data.project.id);
    showChart(
      data.project.id,
      data.project.valueOffer,
      data.project.numberOfUnitsSold
    );
    onSelectForFraction(data.project.valueOffer);
  };

  const handleEntryClick = () => {
    window.location.href = `/portfolio-projects/${data.project.id}`;
  };

  if (selectedEntry == data.project.id) {
    return (
      <Grid container className={"selectedTradeEntry"}>
        <Grid
          item
          xs={2}
          style={{ textAlign: "start" }}
          onClick={() => handleSelect()}
          className="selected-item-text"
        >
          <img
            src={data.project.artist.imageUrl}
            style={{ width: "47px", height: "47px", marginRight: "10px" }}
            alt=""
          />
          {data.project.artist.name}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
          className="selected-item-text"
        >
          {data.project.title}
        </Grid>
        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
          className="item-text"
        >
          {data.project.category}
        </Grid> */}
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
          className="selected-item-text"
        >
          {data.project.projectName}
        </Grid>

        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"value"}
          onClick={() => handleSelect()}
        >
          $ {commaNumber(data.project.pricePerUnit)} / Right
        </Grid> */}
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleSelect()}
        >
          $ {commaNumber(data.project.estimatedPriceBuy)} / Right
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleSelect()}
        >
          $ {commaNumber(data.project.estimatedPriceSell)} / Right
        </Grid>
        {/* 
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleShowStopLimit(id)}
        >
          {stopLimitAmount ? <BarChartIcon /> : null}
        </Grid> */}
        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleEntryClick()}
        >
          View Project
        </Grid> */}
      </Grid>
    );
  } else {
    return (
      <Grid container className={"tradeEntry"}>
        <Grid
          item
          xs={2}
          style={{ textAlign: "start" }}
          onClick={() => handleSelect()}
          className="item-text"
        >
          <img
            src={data.project.artist.imageUrl}
            style={{ width: "47px", height: "47px", marginRight: "10px" }}
          />
          {data.project.artist.name}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
          className="item-text"
        >
          {data.project.title}
        </Grid>
        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
          className="item-text"
        >
          {data.project.category}
        </Grid> */}
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
          className="item-text"
        >
          {data.project.projectName}
        </Grid>

        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"value"}
          onClick={() => handleSelect()}
        >
          $ {commaNumber(data.project.pricePerUnit)} / Right
        </Grid> */}
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleSelect()}
        >
          $ {commaNumber(data.project.estimatedPriceBuy)} / Right
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleSelect()}
        >
          $ {commaNumber(data.project.estimatedPriceSell)} / Right
        </Grid>

        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleShowStopLimit(id)}
        >
          {stopLimitAmount ? <BarChartIcon /> : null}
        </Grid> */}
        {/* <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleEntryClick()}
        >
          View Project
        </Grid> */}
      </Grid>
    );
  }
};

export default TradeEntry;
