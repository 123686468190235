import {
  AccountImage,
  // IsSelectedImage,
  GoPlatinumImage,
  // TrendsImage,
  // PodcastImage,
  // NewsImage,
  // ReviewsImage,
  // ToReadImage,
  EventsImage,
  ServicesImage,
  TradeImage,
  PortofolioImage,
  WatchImage
} from "@Helpers/ImageRoutes/Assets";

export const artistRoutes = [
  // {
  //   type: "head",
  //   text: "ACCOUNT",
  //   link: "/account/profile",
  //   icon: AccountImage,
  //   listItemClassName: null,
  //   listItemTextClass: null,
  //   listItemExtraStyle: { flexDirection: "row" },
  //   iconExtraStyle: {
  //     width: 30,
  //     height: 40,
  //     marginTop: 30,
  //     marginBottom: 30,
  //     marginLeft: 50
  //   },
  //   textExtraStyle: { marginLeft: "10%" },
  //   hasDivider: true
  // },

  {
    type: "primary",
    text: "Create Project & Stock",
    link: "/createProject",
    // icon: WatchImage,
    listItemClassName: "listItem",
    listItemTextClass: "subcategoryText",
    // listSelectedItemTextClass: 'subcategorySelectedText',
    listItemExtraStyle: null,
    iconExtraStyle: null,
    textExtraStyle: null,
    hasDivider: true,
    hardRefresh: true
  },

  {
    type: "primary",
    text: "Logout",
    link: "/logout",
    // icon: WatchImage,
    listItemClassName: "listItem",
    listItemTextClass: "subcategoryText",
    // listSelectedItemTextClass: 'subcategorySelectedText',
    listItemExtraStyle: null,
    iconExtraStyle: null,
    textExtraStyle: null,
    hasDivider: true,
    hardRefresh: true
  },
]

export const routes = [
  // {
  //   type: "head",
  //   text: "ACCOUNT",
  //   link: "/account/profile",
  //   icon: AccountImage,
  //   listItemClassName: null,
  //   listItemTextClass: null,
  //   listItemExtraStyle: { flexDirection: "row" },
  //   iconExtraStyle: {
  //     width: 30,
  //     height: 40,
  //     marginTop: 30,
  //     marginBottom: 30,
  //     marginLeft: 50,
  //     filter: 'invert(100%) sepia(7%) saturate(213%) hue-rotate(272deg) brightness(113%) contrast(90%)',
  //   },
  //   textExtraStyle: { marginLeft: "10%" },
  //   hasDivider: true
  // },

  // {
  //   type: 'primary',
  //   text: 'MUSIC',
  //   link: '/music',
  //   icon: null,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'listItemPrimary',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: { marginRight: '22%' },
  //   hasDivider: false,
  // },
  //
  // {
  //   type: 'primary',
  //   text: 'TRENDS',
  //   link: '/trends',
  //   icon: TrendsImage,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'subcategoryText',
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: { marginRight: '22%' },
  //   hasDivider: false,
  // },
  //
  // {
  //   type: 'primary',
  //   text: 'STREAM',
  //   link: '/stream',
  //   icon: null,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'listItemPrimary',
  //     // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: { marginRight: '20%' },
  //   hasDivider: false,
  // },
  //
  // {
  //   type: 'primary',
  //   text: 'PODCAST',
  //   link: '/podcasts',
  //   icon: PodcastImage,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'subcategoryText',
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: { width: '9%', height: '9%' },
  //   textExtraStyle: { marginRight: '20%' },
  //   hasDivider: false,
  // },

  // {
  //   type: 'primary',
  //   text: 'MEDIA',
  //   link: '/media',
  //   icon: null,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'listItemPrimary',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: { marginRight: '22%' },
  //   hasDivider: false,
  // },

  // {
  //   type: 'secondary',
  //   text: 'NEWS',
  //   link: '/media',
  //   icon: NewsImage,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'subcategoryText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: { width: '9%', height: '9%' },
  //   textExtraStyle: { marginRight: '20%' },
  //   hasDivider: false,
  // },
  //
  // {
  //   type: 'secondary',
  //   text: 'REVIEWS',
  //   link: '/mediaEditorsPick',
  //   icon: ReviewsImage,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'subcategoryText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   hasDivider: false,
  // },
  //
  // {
  //   type: 'secondary',
  //   text: 'TO READ',
  //   link: '/mediaTopStories',
  //   icon: ToReadImage,
  //   listItemClassName: 'listItem',
  //   listItemTextClass: 'subcategoryText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   hasDivider: false,
  // },

  // {
  //   type: "primary",
  //   text: "MERCH",
  //   link: "/market",
  //   icon: null,
  //   listItemClassName: "listItem",
  //   listItemTextClass: "listItemPrimary",
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: { marginTop: 10 },
  //   iconExtraStyle: null,
  //   textExtraStyle: { marginRight: "20%" },
  //   hasDivider: false
  // },

  // {
  //   type: "primary",
  //   text: "EVENTS & TICKETING",
  //   link: "/events",
  //   icon: EventsImage,
  //   listItemClassName: "listItem",
  //   listItemTextClass: "subcategoryText",
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   //   textExtraStyle: { padding: '0' },
  //   hasDivider: false
  // },

  // {
  //   type: "primary",
  //   text: "SERVICES",
  //   link: "/services",
  //   icon: ServicesImage,
  //   listItemClassName: "listItem",
  //   listItemTextClass: "subcategoryText",
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   hasDivider: false
  // },

  {
    type: "primary",
    text: "INVEST",
    link: "/invest/all",
    icon: null,
    listItemClassName: "listItem",
    listItemTextClass: "listItemPrimary",
    listItemExtraStyle: { marginTop: 10 },
    iconExtraStyle: null,
    textExtraStyle: { marginRight: "20%" },
    hasDivider: false,
    hardRefresh: true
  },

  {
    type: "secondary",
    text: "TRADE",
    link: "/trade",
    icon: TradeImage,
    listItemClassName: "listItem",
    listItemTextClass: "subcategoryText",
    // listSelectedItemTextClass: 'subcategorySelectedText',
    listItemExtraStyle: null,
    iconExtraStyle: null,
    textExtraStyle: null,
    hasDivider: false,
    hardRefresh: true
  },

  // {
  //   type: "secondary",
  //   text: "PORTFOLIO",
  //   link: "/portofolio-rights",
  //   icon: PortofolioImage,
  //   listItemClassName: "listItem",
  //   listItemTextClass: "subcategoryText",
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   hasDivider: false,
  //   hardRefresh: true
  // },

  // {
  //   type: "secondary",
  //   text: "WATCH",
  //   link: "/watchlist",
  //   icon: WatchImage,
  //   listItemClassName: "listItem",
  //   listItemTextClass: "subcategoryText",
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   hasDivider: true,
  //   hardRefresh: true
  // },

  // {
  //   type: "primary",
  //   text: "Create Project",
  //   link: "/createProject",
  //   // icon: WatchImage,
  //   listItemClassName: "listItem",
  //   listItemTextClass: "subcategoryText",
  //   // listSelectedItemTextClass: 'subcategorySelectedText',
  //   listItemExtraStyle: null,
  //   iconExtraStyle: null,
  //   textExtraStyle: null,
  //   hasDivider: true,
  //   hardRefresh: true
  // },

  {
    type: "primary",
    text: "Logout",
    link: "/logout",
    // icon: WatchImage,
    listItemClassName: "listItem",
    listItemTextClass: "subcategoryText",
    // listSelectedItemTextClass: 'subcategorySelectedText',
    listItemExtraStyle: null,
    iconExtraStyle: null,
    textExtraStyle: null,
    hasDivider: true,
    hardRefresh: true
  },

  // {
  //   type: "head",
  //   text: "BECOME AN INVESTOR",
  //   link: "/go-platinum",
  //   icon: GoPlatinumImage,
  //   listItemClassName: "nalammm",
  //   listItemTextClass: null,
  //   listItemExtraStyle: { flexDirection: "row", textAlign: "left" },
  //   iconExtraStyle: {
  //     width: 40,
  //     height: 40,
  //     marginTop: 30,
  //     marginBottom: 30,
  //     marginLeft: 45
  //   },
  //   textExtraStyle: {
  //     marginLeft: "10%",
  //     textAlign: "left",
  //     fontSize: "10px !important"
  //   },
  //   hasDivider: true
  // }
];
