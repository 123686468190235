import React, { useState } from "react";
import { GlobalMUIStyles, CenterWrap } from "@Styled/AppStyle";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import commaNumber from "comma-number";

const BuyModalContent = (props) => {
  const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
  const [rightsValue, setRightsValue] = useState(0);

  const {
    offeringPrice,
    setRightsAmout,
    rightsAmount,
    maxRightsAmount,
    // maxRightsAmountMessage,
  } = props;

  return (
    <div className={"buyModalContent"}>
      <div>
        <p>NUMBER OF RIGHTS</p>

        <NumberFormat
          thousandSeparator={true}
          className="nrField"
          id="estimatedValue"
          name="estimatedValue"
          className={GlobalStyle.modalTextInput}
          placeholder="e.g. 100,000"
          // prefix={"$ "}
          value={rightsAmount}
          onValueChange={(values) => {
            const { formattedValue, value, floatValue } = values;

            setRightsAmout(floatValue);
            setRightsValue(floatValue * offeringPrice);
          }}
        />
        {/* <TextField
          variant="outlined"
          fullWidth={true}
          className={GlobalStyle.modalTextInput}
          onChange={(event) => {
            const { value } = event.target;
            event.target.value = event.target.value
              .replace(/[^\dA-Z]/g, "")
              .trim();
            setRightsAmout(value);
            setRightsValue(value * offeringPrice);
          }}
        />
        {/* {maxRightsAmountMessage ? (
          <div className={'maxMessage'}>
            {' '}
            You can buy maximum {maxRightsAmount} rights.{' '}
          </div>
        ) : null} */}
        <p>RIGHTS VALUE</p>
        <h5>&nbsp;&nbsp; $ {commaNumber(rightsValue)}</h5>
      </div>
    </div>
  );
};

export default BuyModalContent;
