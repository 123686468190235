import React, { useEffect } from "react";
import useAuth from "@Hooks/useAuth.hook";
import lscache from "lscache";

const Logout = props => {
  // const context = useAuth();

  const logoutUser = async () => {
    lscache.flush();
    lscache.remove("accessToken");
    window.location = "/login";
  };

  useEffect(() => {
    logoutUser();
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default Logout;
