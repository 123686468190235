import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { GlobalMUIStyles, CenterWrap } from '@Styled/AppStyle';
import { LoginMUIStyles } from '@Styled/Login.style';
import { LogoBtn, LogoRed, loginPlayDisabled } from '@Helpers/ImageRoutes/Logo';
import registerFormSchemaStep2 from '../../FormSchema/registerFormSchemaStep2';
import validate from 'validate.js';

const Step2Signup = props => {
  const { setNextStep, isVisible, mainFormState, setMainFormState } = props;
  const GlobalStyle = GlobalMUIStyles({ color: 'cadetblue' });
  const LoginStyle = LoginMUIStyles();

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, registerFormSchemaStep2);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleNext = () => {
    setMainFormState({
      ...mainFormState,
      step2: {
        password: password,
        confirmPassword: confirmPassword,
      },
    });
    console.log('[Step2]', mainFormState);
    setNextStep(3);
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justify="center"
      direction="column"
      className={LoginStyle.root}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Grid item xs={12}>
        <TextField
          type={'password'}
          id={'password'}
          label={'Password'}
          name="password"
          variant={'outlined'}
          className={GlobalStyle.signUpField}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setPassword(value);
          }}
          error={hasError('password')}
          helperText={
            hasError('password') ? formState.errors.password[0] : null
          }
          value={formState.values.password || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type={'password'}
          id={'passwordConfirm'}
          label={'Verify Password'}
          name="passwordConfirm"
          variant={'outlined'}
          className={GlobalStyle.signUpField}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setConfirmPassword(value);
          }}
          error={hasError('passwordConfirm')}
          helperText={
            hasError('passwordConfirm')
              ? formState.errors.passwordConfirm[0]
              : null
          }
          value={formState.values.passwordConfirm || ''}
        />
      </Grid>
      <Grid item xs={12}>
        {formState.isValid ? (
          <label htmlFor="icon-button-file" className="nextButtonContainer">
            <IconButton
              color="primary"
              component="span"
              onClick={handleNext}
              disabled={!formState.isValid}
              type="submit"
            >
              <h1 style={{ color: "#0F1321", cursor: "pointer" }}>NEXT</h1>
            </IconButton>
          </label>
        ) : (
          <h2 style={{ color: "#7F0909" }}>NEXT</h2>
        )}
      </Grid>
    </Grid>
  );
};

export default Step2Signup;
