import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import dropdownArrow from '@Images/drop-down-arrow.png';
import { useCloseIntervalSelector } from '@Hooks';

const InvestPortofolioRightsListHeader = (props) => {
  const { forActive, showDueDate, handleTableInterval, tableInterval } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandedFilter = () => {
    setIsExpanded(!isExpanded);
  }

  const ExpandedInterval = () => {
    return (
      <div className={'expandedInterval intervalSelector'} style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", marginBottom: "1.75rem" }} onClick={() => handleExpandedFilter()}>
        <div className={`expanded-interval intervalSelector`}>
          <ul className={`category-list intervalSelector`}>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1h"); setIsExpanded(false) }}>1h</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1d"); setIsExpanded(false) }}>1d</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1w"); setIsExpanded(false) }}>1w</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1m"); setIsExpanded(false) }}>1m</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1y"); setIsExpanded(false) }}>1y</li>
          </ul>
        </div>
      </div>
    )
  }

  const IntervalBtn = () => {
    return (
      <div className={"interval intervalSelector"} onClick={() => handleExpandedFilter()}>
        <div className={`portfolio_table_header`}>
          {tableInterval}
        </div>
        <img src={dropdownArrow} alt="" className={"arrow intervalSelector"} style={{ height: 15, width: 15, marginLeft: "0.5rem" }} />
      </div>
    )
  }

  useCloseIntervalSelector('intervalSelector', setIsExpanded, false);

  return (
    <Grid container className={"investPortofolioRightsListHeader intervalSelector"}>
      <Grid item xs={2} style={{ textAlign: "start" }}>
        <div className={`portfolio_table_header`}>
          ARTIST
        </div>
      </Grid>
      <Grid item xs={2} style={{ textAlign: "start" }}>
        <div className={`portfolio_table_header`}>
          PROJECT NAME
        </div>
      </Grid>
      <Grid item xs={forActive ? 1 : 2} style={{ textAlign: "center" }}>
        <div className={`portfolio_table_header`}>
          PROJECT TYPE
        </div>
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <div className={`portfolio_table_header`}>
          RIGHTS (UNITS)
        </div>
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <div className={`portfolio_table_header`}>
          AVERAGE PRICE
        </div>
      </Grid>

      {forActive ? (
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <div className={`portfolio_table_header`}>
            UNITS SOLD
          </div>
        </Grid>
      ) : null}
      {forActive ? (
        <Grid item xs={1} style={{ textAlign: "center" }}>
          <div className={`portfolio_table_header`}>
            PRICE PER UNIT
          </div>
        </Grid>
      ) : null}
      {/* {showDueDate ? (
        <Grid item xs={2} style={{ textAlign: "center" }}>
          IRO DUE DATE
        </Grid>
      ) : null} */}
      {/* {showDueDate ? ( */}

      {window.location.pathname === "/portofolio-rights" ?
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <div>
            {isExpanded ? <ExpandedInterval /> : <IntervalBtn />}
          </div>
          <div className={`portfolio_table_header`}>
            PROFIT / LOSS
          </div>
        </Grid> : null
      }
    </Grid>
  );
};

export default InvestPortofolioRightsListHeader;
