import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import InvestPortofolioRightsEntry from "@Components/InvestPortofolioRightsEntry";
import InvestPortofolioRightsListHeader from "@Components/InvestPortofolioRightsListHeader";
import PortofolioTabs from "@Components/PortofolioTabs";
import { RESTjava } from "@Utils";
import lscache from "lscache";
import Chart from "./Components/Chart";
import Indicators from './Components/Indicators';
import PortfolioTable from './Components/PortfolioTable';

const InvestMyProjectEntry = (props) => {
  const [selectedTab, setSelectedTab] = useState("RIGHTS");
  const [dataList, setDataList] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryPrice, setSelectedEntryPrice] = useState(0);
  const [indicators, setIndicators] = useState(null);
  const [interval, setInterval] = useState("1h");
  const [tableInterval, setTableInterval] = useState("1h");
  const [projectId, setProjectId] = useState(null);
  const [totalVal, setTotalVal] = useState(null);

  const windowWidth = window.innerWidth;

  const handleProjectId = (e) => {
    setProjectId(e)
    console.log("prject id: ", e)
  }
  const handleInterval = (e) => {
    setInterval(e)
  }

  const handleTableInterval = (e) => {
    setTableInterval(e)
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getIndicators();
  }, [interval, projectId])

  useEffect(() => {
    getData();
    console.log("apelez ceva ???")
  }, [tableInterval])

  const getData = async () => {
    await RESTjava.get(
      `/api/project/getMyRights?page=0&size=50&userEmail=${lscache.get(
        "userEmail"
      )}&interval=${tableInterval}`
    )
      .then((answer) => {
        var data = answer.data.content;
        var list = [];
        console.log("ANSWER: ", answer.data, answer.data.content)
        setDataList(answer.data.content);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };

  const handleTotal = async () => {
    await RESTjava.get(
      `/api/project/getMyPortofolioAveragePrice?userEmail=${lscache.get("userEmail")}`)

      .then((answer) => {
        setTotalVal(answer.data.totalAveragePrice)
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  }
  useEffect(() => {
    handleTotal();
  }, [])

  const getIndicators = async () => {
    if (projectId) {

      await RESTjava.get(
        `/api/project/getMyProjectIndicators?interval=${interval}&projectId=${projectId}&userEmail=${lscache.get(
          "userEmail"
        )}`
      )
        .then((answer) => {
          console.log("indicators >>>>", answer.data)
          setIndicators(answer.data)
        }).catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
          }
        })
    }
  }

  const getChartData = async (id, offeringPrice) => {
    console.log("SHOW CHART FOR INVEST: ", id, " with price: ", offeringPrice);
    setSelectedEntryPrice(offeringPrice);
  };

  console.log(" is loading ? :", isPageLoading)


  if (isPageLoading) {
    return (
      <Grid container className={"portofolio"}>
        <Grid container style={{ height: "500px" }}>
          <Grid item xs={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container>
          <InvestPortofolioRightsListHeader />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={"portofolio"}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            {projectId ? <Indicators indicators={indicators} handleInterval={handleInterval} interval={interval} /> : null}
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"lineChart"}>
              {console.log("CHART DATA WITH: ", selectedEntryPrice)}
              <Chart dataset={selectedEntryPrice} />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <InvestPortofolioRightsListHeader showDueDate={true} tableInterval={tableInterval} handleTableInterval={handleTableInterval} />
            <div className={"rightsList"}>
              {dataList && dataList.map((item) => {
                return (
                  <InvestPortofolioRightsEntry
                    item={item}
                    id={item.id}
                    key={item.id}
                    // artist={item.artist}
                    // projectTitle={item.title}
                    // projectType={item.projectType}
                    // rights={item.rights}
                    // value={item.value}
                    valueCurrency={"USD"}
                    growth={"0"}
                    // imgSrc={item.artist.imgSrc}
                    // dueDate={item.dueDate}
                    showDueDate={true}
                    setSelectedEntry={setSelectedEntry}
                    selectedEntry={selectedEntry}
                    showChart={getChartData}
                    handleProjectId={handleProjectId}
                  />
                )
              })}
            </div>
          </Grid>
          <div className={`total_container`}>
            <div className={`total_header`}>
              TOTAL AVERAGE PRICE
            </div>
            <div className={`total_body`}>
              ${Math.round(totalVal * 100) / 100}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default InvestMyProjectEntry;
