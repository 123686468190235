import React from "react";
import { useRouter } from "@Hooks";

const PortofolioTabs = (props) => {
  const Router = useRouter();
  const { selectedTab, setSelectedTab } = props;

  return (
    <div className={"portofolioTabs"}>
      {selectedTab === "RIGHTS" ? (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/portofolio-rights")}
        >
          RIGHTS
        </div>
      ) : (
        <div
          className={"unselectedTabStyle"}
          onClick={() => (window.location.href = "/portofolio-rights")}
        >
          RIGHTS
        </div>
      )}

      {selectedTab === "PROJECTS" ? (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/portofolio-myProjects")}
        >
          MY PROJECTS
        </div>
      ) : (
        <div
          className={"unselectedTabStyle"}
          onClick={() => (window.location.href = "/portofolio-myProjects")}
        >
          MY PROJECTS
        </div>
      )}
    </div>
  );
};

export default PortofolioTabs;
