import React, { useState } from "react";
import { Grid, List } from "@material-ui/core";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import CarouselListItemSecondary from "@Components/CarouselListItemSecondary";
import { useRouter } from "@Hooks";

const MerchList = props => {
  const Router = useRouter();
  const { services } = props;

  const goToServices = serviceID => {
    console.log("GOTO services with id: ", serviceID);
    Router.push({
      pathname: `/marketServices1/${serviceID}`
    });
  };

  return (
    <Grid container style={{ marginTop: "1%" }}>
      <Grid item md={12} lg={12}>
        <Carousel
          plugins={["arrows"]}
          slidesPerPage={8}
          offset={15}
          breakpoints={{
            640: {
              slidesPerPage: 1,
              arrows: false
            },
            900: {
              slidesPerPage: 8,
              arrows: false
            }
          }}
        >
          {services.map(item => (
            <CarouselListItemSecondary
              key={item.id}
              item={item}
              navigateOn={goToServices}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default MerchList;
