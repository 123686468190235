import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import SeeAllTracksList from "@Components/SeeAllTracksList";
import { useRouter } from "@Hooks";
import { REST } from "@Utils";
import lscache from "lscache";

const SeeAllTracks = props => {
  const { setPlayerMediaID } = props;

  const musicList = [];
  const router = useRouter();

  const [dataList, setDataList] = useState(musicList);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getArtistTopSongs = async event => {
    await REST.get(`/artists/getArtistTopSongs/${router.query.artistID}`).then(
      answer => {
        var data = answer.data.body;
        setDataList(data);
        setIsPageLoading(false);
      }
    );
  };

  useEffect(() => {
    if (router.query.artistID) {
      getArtistTopSongs();
    }
  }, [router.query.artistID]);

  const handlePlay = event => {
    setPlayerMediaID(event);
  };

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className="SeeAllTracks">
        <Grid item xs={12} className="titleContainer">
          <p className="titleMedia">All Tracks</p>
        </Grid>
        <Grid item xs={12} className="subTitleContainer">
          <p className="subTitleMedia">Snoop Dogg</p>
        </Grid>
        <Grid item xs={12}>
          {dataList.Songs.map(item => (
            <SeeAllTracksList list={item} handlePlay={handlePlay} />
          ))}
        </Grid>
      </Grid>
    );
  }
};

export default SeeAllTracks;
