import React from "react";
import { useRouter } from "@Hooks";

const LogOutButton = () => {
  const Router = useRouter();
  const logout = () => {
    // Router.push("/login");
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div onClick={logout} className={"LogOutButton"}>
      <p className={"LogOutText"}>Log-Out</p>
    </div>
  );
};

export default LogOutButton;
