import { Button, FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import { REST } from '@Utils';
import React, { useState } from 'react';


////Data field
const DropdownField = (props) => {

  const { id, textData, data, setData } = props;
  const [isSelected, setIsSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  const [newData, setNewData] = useState()

  const handleOnClick = () => {
    setIsSelected(!isSelected);
  }

  const handleChange = (event) => {
    setNewData(event.target.value);
  }


  const handleClose = (option) => {
    setAnchorEl(null);
    setIsSelected(false)
    updateData(option)
  }

  const updateData = async (option) => {
    var SQL = {
      streamingQuality: 0
    }
    var SQM = {
      streamingQuality: 1
    }
    var SQH = {
      streamingQuality: 2
    }

    if (option === "Low") {
      await REST.put(`user/updateStreamingQuality/${data}`, SQL)
        .then(answer => {
          props.refreshData()
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log("ERROR SQ LOW: ", message)
          }
        });
    }
    if (option === "Medium") {
      await REST.put(`user/updateStreamingQuality/${data}`, SQM)
        .then(answer => {
          props.refreshData()
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log("ERROR SQ MEDIUM: ", message)
          }
        });
    }
    if (option === "High") {
      await REST.put(`user/updateStreamingQuality/${data}`, SQH)
        .then(answer => {
          props.refreshData()
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log("ERROR SQ LOW: ", message)
          }
        });
    }



  }

  return (
    <div style={{ marginTop: '0%' }}>
      <ListItemText className="Title">{textData}</ListItemText>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '69%', justifyContent: 'space-between' }}>
        <Button onClick={handleOnClick} className="EditPasswordButton">EDIT</Button>

        {isSelected
          ?
          (
            <FormControl className={"Dropdown"}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newData}
                onChange={handleChange}

                anchorEl={anchorEl}
                autoFocus={true}
              >
                <MenuItem autoFocus={true} value={"High"} onClick={() => handleClose("High")}>High</MenuItem>
                <MenuItem value={"Medium"} onClick={() => handleClose("Medium")}>Medium</MenuItem>
                <MenuItem value={"Low"} onClick={() => handleClose("Low")}>Low</MenuItem>
              </Select>
            </FormControl>
          )
          :
          (
            <ListItemText className="Data">{data}</ListItemText>
          )
        }
      </div>

    </div>
  )
}


export default DropdownField;
