import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ButtonPink } from "@Components";
import { LogoBtn } from "@Helpers/ImageRoutes/Logo";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import { GlobalMUIStyles } from "@Styled/AppStyle";
import { SignupMUIStyles } from "@Styled/Signup.style";
import { theme as ThemeForMUI } from "@Styled/Theme";
import ArtistsMock from "@Mocks/signup/signUpArtists.json";
import ArtistsMockFilter from "@Mocks/signup/signupFilterArtists.json";
import { debounce } from "lodash";
import { REST, hasScrollReachedBottomOfThePage, RESTjava } from "@Utils";
import { LoadingOnPage } from "@Components";
import { unkownArtist } from "@Helpers/ImageRoutes/MusicPlayer";

const Step3Signup = (props) => {
  const { setNextStep, isVisible, mainFormState, setMainFormState } = props;
  const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
  const SignupStyle = SignupMUIStyles();
  const [chooseSignupArtists, setChooseSignupArtists] = useState([]);
  const [selectedArtistsHaystack, setSelectedArtistsHaystack] = useState([]);
  const [getAllArtists, setGetAllArtists] = useState([]);
  const [error, setError] = useState({ message: null });
  const [allArtistsLoaded, setAllArtistsLoaded] = useState(false);

  const [offsetArtists, setOffsetArtists] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const getArtistInfoData = async (event) => {
    await REST.get(`/artists?limit=10&offset=${offsetArtists}`)
      .then((answer) => {
        var data = answer.data.body;
        setOffsetArtists(Number(offsetArtists + 10));
        setGetAllArtists([...getAllArtists, ...data]);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message === "Error: Artist Not Found") {
            setAllArtistsLoaded(true);
          }

          // setIsPageLoading(false);

          setError({
            message,
          });
        }
      });
  };

  const searchThruArtists = async (term) => {
    await RESTjava.get(`/api/artist/searchArtists?artistName=${term}`)
      .then((answer) => {
        setError({ message: null });
        var data = answer.data;
        setGetAllArtists(data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  const handleNext = () => {
    setMainFormState({
      ...mainFormState,
      step3: {
        artists: selectedArtistsHaystack,
      },
    });

    console.log(selectedArtistsHaystack);

    setNextStep(4);
  };

  // const initInfiniteScroll = () => {
  //   console.log('allArtistsLoaded', allArtistsLoaded);

  //   const artistsWrapper = document.getElementById('artistsWrapperRegister');
  //   if (allArtistsLoaded === false) {
  //     if (hasScrollReachedBottomOfThePage(artistsWrapper)) {
  //       getArtistInfoData();
  //     }
  //   }
  // };

  const loadDefaultArtists = async () => {
    setChooseSignupArtists(ArtistsMock.artists);
  };

  const searchForArtist = async (keyword) => {
    setChooseSignupArtists(ArtistsMockFilter.artists);
  };

  const filterArtists = (keyword) => {
    keyword !== "" ? searchThruArtists(keyword) : getArtistInfoData();
  };

  const saveArtistsToProfile = async () => {
    // save selectedArtistsHaystack to endpoint
  };

  const debounceLoadData = useCallback(debounce(filterArtists, 500), []);
  // const debounceScroll = useCallback(debounce(initInfiniteScroll, 500), []);

  const onChangeSearchArtists = (event) => {
    debounceLoadData(event.target.value);
  };

  const addArtistsToSignupQueue = (item, index) => {
    console.log(item, index);
    isInArray(item) ? removeFromArray(item) : addToArray(item);
  };

  const isInArray = (needle) => {
    return !!selectedArtistsHaystack.find((ii, index) => ii.id === needle.id);
  };

  const removeFromArray = (needle) => {
    const final = selectedArtistsHaystack.filter(
      (ii, index) => ii.id !== needle.id
    );

    setSelectedArtistsHaystack(() => [...final]);
  };

  const addToArray = (needle) => {
    setSelectedArtistsHaystack(() => [...selectedArtistsHaystack, needle]);
  };

  useEffect(() => {
    getArtistInfoData();

    // window.addEventListener('scroll', debounceScroll);
  }, []);

  if (isPageLoading) {
    return <LoadingOnPage isVisible={true}></LoadingOnPage>;
  } else {
    return (
      <Grid
        container
        spacing={3}
        alignItems="center"
        justify="center"
        direction="column"
        className={`${SignupStyle.root}, ${SignupStyle.signUpArtistsWrap}`}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <h1>Tell us what you like</h1>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <TextField
            type={"text"}
            id={"searchArtists"}
            label={"Search for an artist"}
            variant={"outlined"}
            className={GlobalStyle.textField}
            fullWidth={true}
            onChange={onChangeSearchArtists}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: ThemeForMUI.colors.sour }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {getAllArtists.length && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            id={`artistsWrapperRegister`}
          >
            <Grid container alignItems="center" justify="center">
              {getAllArtists.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={4}
                    xl={3}
                    md={3}
                    className={`${SignupStyle.signUpArtists}`}
                    onClick={() => addArtistsToSignupQueue(item, index)}
                    key={`signup-artist-${index}`}
                  >
                    <div className={"artistWithOverlay"}>
                      {isInArray(item) && (
                        <div className={"overlayChecked"}>
                          <DoneIcon className={`doneTick`} />
                        </div>
                      )}
                      <img
                        className="artistListImg"
                        src={
                          item &&
                          item.imageUrl &&
                          item.imageUrl.includes("http")
                            ? item.imageUrl
                            : unkownArtist
                        }
                      />
                    </div>

                    <br />
                    <p>{item.name}</p>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {!allArtistsLoaded && (
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <ButtonPink
              onClick={getArtistInfoData}
              variant={"contained"}
              color={"primary"}
              disableElevation
            >
              LOAD MORE
            </ButtonPink>
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} xs={12}>
          <ButtonPink
            onClick={handleNext}
            variant={"contained"}
            color={"primary"}
            disabled={!selectedArtistsHaystack.length}
            disableElevation
          >
            DONE
          </ButtonPink>
        </Grid>
      </Grid>
    );
  }
};

export default Step3Signup;
