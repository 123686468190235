import React from 'react';

const InvestHeader = props => {
  return (
    <div className={'investHeader'}>
      <h1 className="invest-title">INVEST</h1>
      <div className={'investSubtitle'}>
        <h5 className="invest-title">Select to invest</h5>
      </div>
    </div>
  );
};

export default InvestHeader;
