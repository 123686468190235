import React, { useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";

import Intro from "./components/intro";
import FirstStep from "./components/firstStep";
import SecondStep from "./components/secondStep";
import ThridStep from "./components/thirdStep";
import { debounce } from "lodash";
import { RESTjava } from "@Utils";

export const CreateProject = () => {
  const [currentStep, setCurrentStep] = useState("intro");
  const [SlingshotType, setSlingshotType] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [getAllArtists, setGetAllArtists] = useState([]);
  const [error, setError] = useState({ message: null });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const searchThruArtists = async (term) => {
    if (term.length >= 3) {
      await RESTjava.get(`api/artist/searchArtists?artistName=${term}`)
        .then((answer) => {
          setError({ message: null });
          var data = answer.data;
          setGetAllArtists(data);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;

            setError({
              message,
            });
          }
        });
    }
  };

  const debounceLoadData = useCallback(debounce(searchThruArtists, 500), []);

  const handleChange = (event) => {
    event.persist();

    if (
      event.target.name === "artistOrBand" ||
      event.target.name === "endorsedBy"
    ) {
      debounceLoadData(event.target.value);
    }

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.type === "file"
              ? event.target.files[0]
              : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  console.log("[Create Project] FormState", formState);

  return (
    <Grid className="createProject">
      {currentStep === "intro" ? (
        <Intro
          setFormState={setFormState}
          currentStep={currentStep}
          setCurrentStep={(event) => setCurrentStep(event)}
          handleChange={handleChange}
          formState={formState}
          setSlingshotType={(event) => setSlingshotType(event)}
        />
      ) : currentStep === "firstStep" ? (
        <FirstStep
          getAllArtists={getAllArtists}
          setFormState={setFormState}
          currentStep={currentStep}
          setCurrentStep={(event) => setCurrentStep(event)}
          handleChange={handleChange}
          formState={formState}
        />
      ) : currentStep === "secondStep" ? (
        <SecondStep
          setFormState={setFormState}
          currentStep={currentStep}
          setCurrentStep={(event) => setCurrentStep(event)}
          handleChange={handleChange}
          formState={formState}
          getAllArtists={getAllArtists}
        />
      ) : currentStep === "thirdStep" ? (
        <ThridStep
          setFormState={setFormState}
          SlingshotType={SlingshotType}
          currentStep={currentStep}
          setCurrentStep={(event) => setCurrentStep(event)}
          handleChange={handleChange}
          formState={formState}
          setProjectData={(event) => setProjectData(event)}
          projectData={projectData}
        />
      ) : (
        <div>We are working on this feature</div>
      )}
    </Grid>
  );
};

export default CreateProject;
