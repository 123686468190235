import React, { useState, useRef, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import dropdownArrow from '@Images/drop-down-arrow.png';
import upArrow from '@Images/up-arrow.png';
import downArrow from '@Images/down-arrow.png';
import { useCloseIntervalSelector } from '@Hooks';

const Indicators = (props) => {
    const { indicators, handleInterval, interval } = props

    const [isExpanded, setIsExpanded] = useState(false)

    useCloseIntervalSelector('intervalSelector', setIsExpanded, false);

    const handleExpandedFilter = () => {
        setIsExpanded(!isExpanded);
    }

    const ExpandedInterval = () => {
        return (
            <div className={'expandedInterval intervalSelector'} style={{ display: 'flex', flexDirection: 'row' }} onClick={() => handleExpandedFilter()}>
                <div className={`expanded-interval intervalSelector`}>
                    <ul className={`category-list intervalSelector`}>
                        <li className={`category-interval`} onClick={() => { handleInterval("1h"); setIsExpanded(false) }}>1h</li>
                        <li className={`category-interval`} onClick={() => { handleInterval("1d"); setIsExpanded(false) }}>1d</li>
                        <li className={`category-interval`} onClick={() => { handleInterval("1w"); setIsExpanded(false) }}>1w</li>
                        <li className={`category-interval`} onClick={() => { handleInterval("1m"); setIsExpanded(false) }}>1m</li>
                        <li className={`category-interval`} onClick={() => { handleInterval("1y"); setIsExpanded(false) }}>1y</li>
                    </ul>
                </div>
            </div>
        )
    }

    const IntervalBtn = () => {
        return (
            <div className={"interval intervalSelector"} onClick={() => handleExpandedFilter()}>
                {interval}
                <img src={dropdownArrow} alt="" className={"arrow intervalSelector"} style={{ height: 15, width: 15, marginLeft: "0.5rem" }} />
            </div>
        )
    }

    return (
        <div className={"indicatorsContainer intervalSelector"}>
            <div className={"actual_value"}>
                $ {indicators && indicators.actualValue}
            </div>
            <div className={"variation_container"}>
                <div className={"value"} >
                    $ {indicators && indicators.variationValue}
                </div>
                <div className={"percentage"}>
                    {indicators && indicators.sensOfVariation ?
                        <img className={"arrow"} src={upArrow} alt="" style={{ height: 15, width: 12, marginLeft: "0.5rem" }} />
                        :
                        <img className={"arrow"} src={downArrow} alt="" style={{ height: 15, width: 12, marginLeft: "0.5rem" }} />
                    }

                    {indicators && Math.round(indicators.variationPercentage * 100) / 100}%
                </div>
                {isExpanded ? <ExpandedInterval /> : <IntervalBtn />}
                {/* <div className={"interval"}>
                    24H
                    <img src={dropdownArrow} alt="" className={"arrow"} style={{ height: 15, width: 15, marginLeft: "0.5rem" }} />
                </div> */}
            </div>
        </div>
    )
}

export default Indicators;