import React from 'react';

import { Button, Grid } from '@material-ui/core';

const StatementListHeader = props => {
  return (
    <Grid
      container
      className={'AccountStatementHeaderLine'}
      style={{ marginBottom: '20px', marginTop: '20px' }}
    >
      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div className={'listHeaderText'}>DATE&nbsp;&nbsp;</div>
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={'listHeaderText'}>INWARD</div>
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={'listHeaderText'}>OUTWARD</div>
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className={'listHeaderText'}>BALANCE</div>
      </Grid>
    </Grid>
  );
};

export default StatementListHeader;
