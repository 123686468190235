import React, { useState } from "react";
import { Grid, List } from "@material-ui/core";

import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import MediaCarouselListItem from "@Components/MediaCarouselListItem";
import { useRouter } from "@Hooks";

const MediaTrendingList = props => {
  const Router = useRouter();
  const { trendingList } = props;

  const goToMedia = mediaID => {
    console.log("GOTO MEDIA DETAILS FOR INVEST ID: ", mediaID);
    Router.push({
      pathname: `/mediaItem/${mediaID}`
    });
  };

  return (
    <Grid container className="MediaTrendingList">
      <Grid item md={12} lg={12} className="carouselContainer">
        <p className="carouselTitle">Trending Stories</p>
        <Carousel
          className={"carouselName"}
          plugins={["arrows"]}
          slidesPerPage={8}
        >
          {trendingList.map(item => (
            <MediaCarouselListItem
              item={item}
              key={item.id}
              navigateOn={goToMedia}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default MediaTrendingList;
