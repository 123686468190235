import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { GlobalMUIStyles, CenterWrap } from "@Styled/AppStyle";
import { LoginMUIStyles } from "@Styled/Login.style";
import { LogoBtn, LogoRed, loginPlayDisabled } from "@Helpers/ImageRoutes/Logo";
import registerFormSchemaStep1 from "../../FormSchema/registerFormSchemaStep1";
import validate from "validate.js";
import { REST, RESTjava } from "@Config/Axios/restInstances";
import axios from "axios";

const Step1Signup = props => {
  const {
    setNextStep,
    isVisible,
    mainFormState,
    setMainFormState,
    registerError
  } = props;
  const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
  const LoginStyle = LoginMUIStyles();

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, registerFormSchemaStep1);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [age, setAge] = useState(null);

  const handleNext = async () => {
    const checkUsername = await RESTjava.get(
      `/api/user?username=${formState.values.username}`
    );
    const checkEmail = await RESTjava.get(
      `/api/user/checkEmail?email=${formState.values.email}`
    );

    axios
      .all([checkUsername, checkEmail])
      .then(answer => {
        const usernameFound = answer[0].data.found,
          emailFound = answer[1].data.found;

        if (!usernameFound && !emailFound) {
          setMainFormState({
            ...mainFormState,
            step1: {
              username: username,
              email: email,
              age: age
            }
          });
          setNextStep(2);
        } else {
          setFormState(formState => ({
            ...formState,
            errors: {
              ...formState,
              usernameExists: usernameFound ? "Username already taken" : false,
              emailExists: emailFound ? "Email already taken" : false
            }
          }));
        }
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
        }
      });
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems={`center`}
      justify={`center`}
      style={{ display: isVisible ? "block" : "none" }}
    >
      {registerError && (
        <Grid item xl={12} lg={12} md={12} xs={12}>
          {registerError.username === "taken" && (
            <p>Username is already taken. Please choose a different one.</p>
          )}
          {registerError.email === "taken" && (
            <p>Email is already taken. Please choose a different one.</p>
          )}
        </Grid>
      )}
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id="username"
          name="username"
          label="Username"
          variant={"outlined"}
          autocomplete="no"
          className={GlobalStyle.signUpField}
          autocomplete="false"
          // inputProps={{
          //   autocomplete: "new-user",
          //   form: {
          //     autocomplete: "no",

          //   },
          // }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setUsername(value);
          }}
          error={hasError("username")}
          helperText={
            hasError("username") ? formState.errors.username[0] : null
          }
          value={formState.values.username || ""}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          type="email"
          id="email"
          name="email"
          label={"Email"}
          variant={"outlined"}
          className={GlobalStyle.signUpField}
          inputProps={{
            autocomplete: "new-email",
            form: {
              autocomplete: "off"
            }
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setEmail(value);
          }}
          error={hasError("email")}
          helperText={hasError("email") ? formState.errors.email[0] : null}
          value={formState.values.email || ""}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          type={"number"}
          id={"age"}
          label={"Age"}
          name="age"
          variant={"outlined"}
          className={GlobalStyle.signUpField}
          inputProps={{
            autocomplete: "new-age",
            form: {
              autocomplete: "off"
            }
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setAge(value);
          }}
          error={hasError("age")}
          helperText={hasError("age") ? formState.errors.age[0] : null}
          value={formState.values.age || ""}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <p>{formState.errors.usernameExists}</p>
        <p style={{ color: "#7F0909" }}>{formState.errors.emailExists}</p>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        {formState.isValid ? (
          <label htmlFor="icon-button-file" className="nextButtonContainer">
            <IconButton
              color="primary"
              component="span"
              onClick={handleNext}
              disabled={!formState.isValid}
              type="submit"
            >
              <h1 style={{ color: "#0F1321", cursor: "pointer" }}>NEXT</h1>
            </IconButton>
          </label>
        ) : (
          <h2 style={{ color: "#7F0909" }}>NEXT</h2>
        )}
      </Grid>
    </Grid>
  );
};

export default Step1Signup;
