import React, { useState, useEffect } from "react";

import { Button, Grid } from "@material-ui/core";
import BankAccount from "./BankAccount";
import CurrencyAmount from "./CurrencyAmount";
import { useRouter } from "@Hooks";
import { RESTjava } from "@Utils";
import lscache from "lscache";
import moment from "moment";

const BankLine = () => {
  const Router = useRouter();
  const [isPageLoading, setIsPageLoading] = useState(null);

  const [currency, setCurrency] = useState();
  const [amount, setAmount] = useState();
  const [cardNumber, setCardNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [cvc, setCvc] = useState("");

  const getData = async event => {
    console.log("aici 2");
    await RESTjava.get(
      `/api/wallet/getWallet?userEmail=${lscache.get("userEmail")}`
    )
      .then(answer => {
        console.log("WALLET DATA: ", answer);
        setCurrency(answer.data.currency);
        setAmount(answer.data.amount);
        setCardNumber(answer.data.card.cardNumber);
        setCvc(answer.data.card.cvc);
        setExpireDate(answer.data.card.expireDate);
        setFullName(answer.data.card.fullName);
        setIsPageLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("ERROR WALLET: ", message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <Grid className={"BankAccountLine"} item md={12} lg={12}>
        <Grid item md={4} lg={4}>
          <BankAccount
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            setFullName={setFullName}
            setCvc={setCvc}
            setExpireDate={setExpireDate}
            fullName={fullName}
            cvc={cvc}
            expireDate={expireDate}
            refreshData={getData}
          />
        </Grid>
        <Grid item md={4} lg={4}>
          <div className={"StatementButton"}>
            <Button
              className={"StatementTitle"}
              onClick={() => Router.push("/wallet/statement")}
            >
              {" "}
              VIEW STATEMENT{" "}
            </Button>
          </div>
        </Grid>
        <Grid item md={4} lg={4}>
          <div>
            <CurrencyAmount
              textData={"Currency"}
              currency={currency}
              amount={amount}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default BankLine;
