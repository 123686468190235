import React, {useState} from 'react';

import { Button, Grid, List, ListItem, ListItemText, TextField } from '@material-ui/core';

const LinkField = (props) => {

    const { id, textData, data, setData} = props;
    const [isSelected, setIsSelected] = React.useState(false);
    const [currentData, setCurrentData] = React.useState(data);

    const handleOnClick = () => {
      window.open('http://catalin.wtzconsult.com:3100/public/terms-and-conditions-template.pdf');
    }  

    return (
      <div style={{marginTop: '0%'}}>
        <div style={{display: 'flex', flexDirection: 'row-reverse', width: '69%', justifyContent: 'space-between'}}>
          <Button onClick={handleOnClick}  className="EditData">READ</Button>
          <ListItemText className="LinkTitle">{textData}</ListItemText>          
        </div>    
      </div>
    )
}

export default LinkField;
