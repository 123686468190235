import React from "react";
import TextField from "@material-ui/core/TextField";
import { GlobalMUIStyles, CenterWrap } from "@Styled/AppStyle";
import NumberFormat from "react-number-format";
const AmountInput = (props) => {
  const { amount, setAmount, currency } = props;

  const GlobalStyle = GlobalMUIStyles({
    color: "#f2f2f2",
  });

  return (
    <div className={"amountInput"}>
      <div className={"amount"}>
        <NumberFormat
          thousandSeparator={true}
          className={GlobalStyle.amountInput}
          style={{
            backgroundColor: "#f2f2f2",
            color: "#0f1321",
            width: "100%",
            border: "none",
            outline: "none",
            paddingLeft: "5px",
            paddingRight: "5px",
            textAlign: "end",
          }}
          id="percentage"
          name="percentage"
          placeholder="e.g. 30"
          value={amount}
          onValueChange={(values) => {
            const { formattedValue, value, floatValue } = values;
            setAmount(floatValue);
          }}
        />
      </div>
      <div className={"currency-label"}>
        <label>{currency}</label>
      </div>
    </div>
  );
};

export default AmountInput;
