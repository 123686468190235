import React from "react";
import Grid from '@material-ui/core/Grid';


import MarketFilters from './Components/Header/MarketFilters';
import Services from './Components/Services/Services';
import Banner from './Components/Banner/Banner';
import Agreements from './Components/Agreemets/Agreements';

export const MarketServices2 = () => {

    return (
        <Grid container className={'market-Services2-root'}>
            {/* <Grid item xs={12}>
                <MarketFilters />
            </Grid> */}
            <Grid item xs={12} md={12} lg={12} xl={12} className={'services2-body'}>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <Services />

                </Grid>

                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <Banner />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className={'services-agreement-container'}>
                <Agreements />
            </Grid>
        </Grid>
    );
};

export default MarketServices2;
