import React, {useState} from 'react';








const AccountHeader = () => {
    
    return (
        <div>
           <h1 className="AccountHeader">ACCOUNT</h1>
        </div>
    )
  }

  export default AccountHeader;