import React from "react";
import Grid from "@material-ui/core/Grid";

const InvestEndorsedBy = (props) => {
  const { data } = props;
  console.log(data);
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h5 className={"investEndorsedBy"}>ENDORSED BY : </h5>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={data && data.data.endorsedBy && data.data.endorsedBy.imageUrl}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "10px",

              marginLeft: "20px",
            }}
          />
          <p
            className={"investEndorsedByArtistName"}
            style={{ fontSize: "20px" }}
          >
            {data && data.data.endorsedBy && data.data.endorsedBy.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvestEndorsedBy;
