import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import AmountInput from '@Components/AmountInput';

const StopOrder = () => {
    const [currency, setCurrency] = useState('$');

    const [buyLimit, setBuyLimit] = useState(0);
    const [sellLimit, setSellLimit] = useState(0);
    const [quantityToBuy, setQuantityToBuy] = useState(0);
    const [quantityToSell, setQuantityToSell] = useState(0);
    const [buyTakeProfit, setBuyTakeProfit] = useState(0);
    const [buyStopLoss, setBuyStopLoss] = useState(0);
    const [sellTakeProfit, setSellTakeProfit] = useState(0);
    const [sellStopLoss, setSellStopLoss] = useState(0);

    const handleBuy = (buy) => {
        console.log("buyyyyy", buy)
    }

    const handleSell = (sell) => {
        console.log("selllll", sell)
    }

    return (
        <Grid container className='amountField'>
            <Grid item xs={6} className="buyInputs">
                <div className="orderInput">
                    TAKE PROFIT
                    <AmountInput
                        name="buyTakeProfit"
                        amount={buyTakeProfit}
                        setAmount={setBuyTakeProfit}
                        currency={currency}
                    />

                </div>
                <div className="orderInput">
                    STOP LOSS
                    <AmountInput
                        name="buyStopLoss"
                        amount={buyStopLoss}
                        setAmount={setBuyStopLoss}
                        currency={currency}
                    />
                </div>
                <div className="orderInput">
                    LIMIT
                    <AmountInput
                        amount={buyLimit}
                        setAmount={setBuyLimit}
                        currency={currency}
                    />
                </div>
                <div className="orderInput">
                    QUANTITY
                    <AmountInput
                        amount={quantityToBuy}
                        setAmount={setQuantityToBuy}
                        currency={""}
                    />
                </div>
            </Grid>
            <Grid item xs={6} className="sellInputs">

                <div className="orderInput">
                    TAKE PROFIT
                    <AmountInput
                        name="sellTakeProfit"
                        amount={sellTakeProfit}
                        setAmount={setSellTakeProfit}
                        currency={currency}
                    />
                </div>
                <div className="orderInput">
                    STOP LOSS
                    <AmountInput
                        name="sellStopLoss"
                        amount={sellStopLoss}
                        setAmount={setSellStopLoss}
                        currency={currency}
                    />
                </div>
                <div className="orderInput">
                    LIMIT
                    <AmountInput
                        amount={sellLimit}
                        setAmount={setSellLimit}
                        currency={currency}
                    />
                </div>
                <div className="orderInput">
                    QUANTITY
                    <AmountInput
                        amount={quantityToSell}
                        setAmount={setQuantityToSell}
                        currency={""}
                    />
                </div>
            </Grid>

            <Grid item container className="totalContainer">
                <Grid item xs={6} className="totalAmmount">
                    <p className="inputName">TOTAL</p>
                    <h4>{buyLimit * quantityToBuy}</h4>
                </Grid>

                <Grid item xs={6} className="totalAmmount">
                    <p className="inputName">TOTAL</p>
                    <h4>{sellLimit * quantityToSell}</h4>
                </Grid>
            </Grid>

            <Grid item xs={12} className="buySellBtns">
                <Grid item xs={6}>
                    <div className="addCommentBtnContainer" onClick={() => handleBuy(11)}>
                        <div className="addCommentBtnBg">
                            <p className="addCommentBtnText">BUY</p>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="addCommentBtnContainer" onClick={() => handleSell(11)}>
                        <div className="addCommentBtnBg">
                            <p className="addCommentBtnText">SELL</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StopOrder;