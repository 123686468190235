import React from 'react';

import { Button, List, Grid } from '@material-ui/core';

const RoyaltiesLine = props => {
  const { setShowMoreRoyalties, hasRoyalties } = props;

  const handleViewMore = () => {
    //ViewMorePage
    setShowMoreRoyalties(true);
  };

  return (
    <Grid
      container
      className={'AccountRightsListHeader'}
      style={{ marginBottom: '20px', marginTop: '80px' }}
    >
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div className={'listHeaderText'}>ROYALTIES&nbsp;&nbsp;</div>
        {hasRoyalties ? (
          <div onClick={handleViewMore} className={'listHeaderText2'}>
            View more
          </div>
        ) : null}
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={'listHeaderText3'}>REVENUE SHARE</div>
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className={'listHeaderText3'}>REVENUE</div>
      </Grid>
    </Grid>
  );
};

export default RoyaltiesLine;
