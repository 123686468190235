import React from "react";
import Grid from "@material-ui/core/Grid";

const InvestListHeader = (props) => {
  return (
    <Grid container spacing={0} className={"listHeader"}>
      {/* <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>ARTIST</p>
      </Grid> */}
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>PROJECT NAME</p>
      </Grid>
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>PROJECT TYPE</p>
      </Grid>

      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>RIGHTS OFFERED</p>
      </Grid>
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>RIGHTS SOLD</p>
      </Grid>
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>SHARE</p>
      </Grid>
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>OFFERING PRICE</p>
      </Grid>
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>IRO START DATE</p>
      </Grid>
      <Grid item xs={1}>
        <p style={{ color: "#0F1321" }}>IRO END DATE</p>
      </Grid>
    </Grid>
  );
};

export default InvestListHeader;
