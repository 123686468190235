import React from "react";
import { Grid } from "@material-ui/core";
import { useRouter } from "@Hooks";

const LogOutButton = () => {
  const Router = useRouter();
  const logout = () => {
    // Router.push("/login");
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <Grid item md={4} lg={4}>
      <div onClick={logout} className={"LogOutWalletButton"}>
        <p className={"LogOutText"}>Log-Out</p>
      </div>
    </Grid>
  );
};

export default LogOutButton;
