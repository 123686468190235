import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import SeeAllEventsList from "@Components/SeeAllEventsList";
import { useRouter } from "@Hooks";
import { RESTjava } from "@Utils";

const SeeAllEvents = () => {
  const router = useRouter();
  const [selectedTab, setSelectedTab] = useState("MUSIC");
  const [dataList, setDataList] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    if (router.query.artistID) {
      console.log("data in ");

      getData();
    }
  }, [router.query.artistID]);

  const getData = async event => {
    await RESTjava.get(
      `api/event/artist/getArtistEvents?artistId=${router.query.artistID}&page=0&size=20`
    )
      .then(answer => {
        var data = answer.data.content;
        setDataList(data);
        console.log("data in events", data);
        setIsPageLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("ERROR: ", message);
        }
      });
  };

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className="SeeAllEvents">
        <Grid item xs={12} className="titleContainer">
          <p className="titleMedia">All Events</p>
        </Grid>
        <Grid item xs={12} className="subTitleContainer">
          <p className="subTitleMedia">{router.query.artistName}</p>
        </Grid>
        <Grid item xs={12}>
          {dataList.map(item => (
            <SeeAllEventsList list={item} />
          ))}
        </Grid>
      </Grid>
    );
  }
};

export default SeeAllEvents;
