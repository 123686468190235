import { useRouter } from "@Hooks";
import {
  Button, ListItemText,
  TextField
} from "@material-ui/core";
import { REST } from "@Utils";
import React, { useState } from "react";



////Data field
const DataField = props => {
  const Router = useRouter();
  const { id, textData, data, setData } = props;
  const [isSelected, setIsSelected] = useState(false);
  const [newData, setNewData] = useState();

  const handleOnClick = async event => {
    setIsSelected(!isSelected);
    if (isSelected) {
      updateData();
    }
  };

  const handleChange = event => {
    setNewData(event.target.value);
  };

  const handleCancel = () => {
    setIsSelected(false);
    setNewData("");
  };

  const updateData = async event => {
    if (newData.length > 0) {
      var UN = {
        username: "" + newData
      };
      var EM = {
        email: "" + newData
      };
      var AD = {
        address: "" + newData
      };
      if (textData === "User Name") {
        await REST.put(`/user/${data}/update`, UN)
          .then(answer => {
            props.refreshData();
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              console.log(`Error: `, err.response.data)
            }
          });
      }
      if (textData === "Email") {
        // await REST.put(`${rURL}/user/update/email`, EM)
        await REST.put(`/user/${data}/update/email`, EM)
          .then(answer => {
            props.refreshData();
          })
          .catch(err => {
            if (err && err.response && err.response.data) {
              console.log(`Error: `, err.response.data)
            }
          });
      }
    }
    if (textData === "Physical address") {
      await REST.put(`user/updateUserAddress/${data}`, AD)
        .then(answer => {
          props.refreshData();
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            console.log("ERROR ADDRESS: ", err.response.data);
          }
        });
    }
  };

  return (
    <div style={{ marginTop: "0%" }}>
      <ListItemText className="Title">{textData}</ListItemText>

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          width: "69%",
          justifyContent: "space-between"
        }}
      >
        <Button onClick={handleOnClick} className="EditData">
          {isSelected ? "SAVE" : "EDIT"}
        </Button>
        {isSelected ? (
          <Button onClick={handleCancel} className="EditData">
            CANCEL
          </Button>
        ) : null}
        {isSelected ? (
          <TextField
            className="Data"
            size="small"
            id="standard-basic"
            color="secondary"
            variant="filled"
            placeholder={data}
            fullWidth={true}
            autoFocus={true}
            onChange={handleChange}
          />
        ) : (
          <ListItemText className="Data">{data}</ListItemText>
        )}
      </div>
    </div>
  );
};

export default DataField;
