import React from 'react';
import Grid from '@material-ui/core/Grid';
import { CenterWrap } from '@Styled/AppStyle';
import { LoginMUIStyles, TermsPrivacyUL } from '@Styled/Login.style';

const Footer = () => {
  const LoginStyle = LoginMUIStyles();

  return (
    <CenterWrap>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justify="center"
        direction="column"
        className={`${LoginStyle.root} pos-rel`}
      >
        {/* <Grid item xs={12} md={12} xl={12} className="mt-2-rem">
          <TermsPrivacyUL>
            <li>
              <a href={'/terms'}>Terms &amp; Conditions</a>
            </li>
            <li>
              <a href={'/privacy'}>Privacy Policy</a>
            </li>
          </TermsPrivacyUL>
        </Grid> */}
      </Grid>
    </CenterWrap>
  );
};

export { Footer };
