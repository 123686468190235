const registerFormSchemaStep1 = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },

  email: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
    },
    email: {
      message: "doesn't look like a valid email"
    },
  },
  age: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 3,
    }
  },
};

export default registerFormSchemaStep1;
