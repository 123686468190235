import React, { useState, useEffect } from "react";
import { Button, Input } from "@material-ui/core";
import CustomModal from "@Components/CustomModal";
import { RESTjava } from "@Config/Axios/restInstances";
import lscache from "lscache";
import TextField from "@material-ui/core/TextField";

const AddMoneyToWallet = props => {
  const { moneyModalVisible, setMoneyModalVisible, refreshPage } = props;

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      amount: 0
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const addMoney = async () => {
    await RESTjava.post(
      `api/wallet/addMoney?amount=${
        formState.values.amount
      }&userEmail=${lscache.get("userEmail")}`
    )
      .then(answer => {
        setMoneyModalVisible(false);
        refreshPage();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <CustomModal
      open={moneyModalVisible}
      setOpen={setMoneyModalVisible}
      buttonPressed={addMoney}
      buttonText={"ADD MONEY TO WALLET"}
    >
      <TextField
        id="amount"
        name={"amount"}
        label={"Amount"}
        variant={"outlined"}
        onChange={handleChange}
        value={formState.values.amount || 0}
      />
    </CustomModal>
  );
};

export default AddMoneyToWallet;
