import React from "react";
import { RouterPush } from "@Utils";

import InvestPreview from "@Components/InvestPreview";
import InvestListHeader from "@Components/InvestListHeader";
import { useRouter } from "@Hooks";
import InvestTable from './Components/InvestTable';

const InvestList = (props) => {
  const Router = useRouter();
  const { list, tab, selectedCategory } = props;

  const goToInvestDetails = (investID, selectedItem) => {
    console.log("GOTO INVEST DETAILS FOR INVEST ID: ", investID);
    Router.push({
      pathname: `/invest-into/${investID}`,
      query: { tab: tab, selectedCategory: selectedCategory },
      state: selectedItem
    });
  };

  console.log("[Test]", list);

  const windowWidth = window.innerWidth;

  return (
    <div className={"page invest_table_contianer"}>
      <div className={`inv_table`}>
        {windowWidth > 500 ?
          <div>
            <InvestListHeader />
            {list?.map((item, index) => (
              <InvestPreview
                id={item?.id}
                key={`invest-list-${index}`}
                artist={item?.artist}
                projectType={item?.projectType}
                rights={item?.rights}
                share={item?.share}
                offeringPrice={item?.offeringPrice}
                imgSrc={item?.imgSrc}
                goToInvestDetails={goToInvestDetails}
                item={item}
              />
            ))}
          </div>
          :
          <div style={{ width: "100%" }}>
            {list.map((item, index) => (
              <InvestTable
                id={item.id}
                key={`invest-list-${index}`}
                artist={item.artist}
                projectType={item.projectType}
                rights={item.rights}
                share={item.share}
                offeringPrice={item.offeringPrice}
                imgSrc={item.imgSrc}
                goToInvestDetails={goToInvestDetails}
                item={item}
              />
            ))}
          </div>
        }
      </div>
    </div>
  );
};

export default InvestList;
