export const artistProfileDetails = [
  {
    date: 'MAR9',
    region: 'MIAMI, FL, USA',
    location: 'AMWAY CENTER',
    sold: true,
    
    genre: 'Rap',
    isButton: false,
    isActive: true,
  },
  {
    date: 'MAR10',
    region: 'MIAMI, FL, USA',
    location: 'AMWAY CENTER',
    sold: true,

    genre: 'HIP-HOP',
    isButton: false,
    isActive: true,
  },
  {
    date: 'MAR11',
    region: 'MIAMI, FL, USA',
    location: 'AMWAY CENTER',
    sold: false,

    genre: 'experimentantal',
    isButton: false,
    isActive: true,
  },
];
